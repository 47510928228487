define('prizeothankyou',["jquery", 'snabbdom', "prizeoutils"], function ($, snabbdom, prizeoutils) {
    "use strict";

    if(cfg.page !== 'thank-you') return;

    /**
     * Celebrity message textarea - display characters left and validate
     **/
    $(function () {
        var celebrityMessageLimit = 200;

        $('#celebrity-message-textarea').keyup(function (e) {
            var left = celebrityMessageLimit - $(this).val().length;
            var $charactersLeftLabel = $('#celebrity-message-characters-left');
            $charactersLeftLabel.text(left);
            if (left < 0) {
                $charactersLeftLabel.addClass('negative');
            } else {
                $charactersLeftLabel.removeClass('negative');
            }
        });

        $('#thank-you-page-form').submit(function (e) {
            if ($('#celebrity-message-textarea').val().length > celebrityMessageLimit) {
                // allowed number of characters exceeded

                // disable form submit
                e.preventDefault();

                $('body,html').animate({scrollTop: 0}, 500);

                // show error message
                messages.error(polyglot.t('thankYou.longMsg.js'));
            }

            // message is empty
            if ($('#celebrity-message-textarea').val() == '') {
                // disable form submit
                e.preventDefault();

                $('body,html').animate({scrollTop: 0}, 500);

                // show error message
                messages.error(polyglot.t('thankYou.enterMsg.js'));
            }
        });

        window.clickedLikeOrFollow = false;
    });

    $('.js-crossevent').click(function(event) {
        event.preventDefault();

        var link = $(this);

        try {
            ga('send', 'event', 'Internal Linking', 'Cross Campaign Link Click', 'thank-you|'+cfg.campaign.shortUrl+'|' + link.attr('data-landing'), {
                hitCallback: function() {
                window.location = link.attr('href');
                }
            });
        }
        catch(err) {
            window.location = link.attr('href');
        }

        return false;
    });
});

