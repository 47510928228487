require.config({
    paths: {
        jquery: "./jquery-2.1.3.min",
        snabbdom: "./vendor/snabbdom/bundle",
        json: "./json2.min",
        prizeoapplication: "./prizeo.application",
        prizeorafflepage: "./prizeo.rafflepage",
        jqcookie: "./jquery.cookie",
        jqcheckbox: "./jquery.checkbox",
        jqplaceholders: "./jquery.placeholders",
        jqtimeago: "./jquery.timeago",
        mail_validator: "./mail_validator",
        jqeasing: "./jquery.easing",
        jqfancybox: "./jquery.fancybox",
        jqcollapser: "./jquery.collapser",
        jqappear: "./jquery.appear",
        bootstrapmodal: "./bootstrap",
        prizeodatacollection: "./prizeo.datacollection",
        prizeofacebook: "./prizeo.facebook",
        prizeopayment: "./prizeo.payment",
        prizeosignin: "./prizeo.signin",
        prizeothankyou: "./prizeo.thankYou",
        prizeotwitter: "./prizeo.twitter",
        prizeoutils: "./prizeo.utils",
        prizeomagnify: "./prizeo.magnify",
        jqcomplexify: "./jquery.complexify",
        jqchosen: "./jquery.chosen",
        hideshowpassword: "./hideShowPassword",
        jqcountdown: "./jquery.countdown",
        sly: "./sly.min",
        brightcove: "./BrightcoveExperiences",
        selectize: "./selectize.min",
        i4go: "./jquery.i4goTrueToken"
    },
    shim: {
        jquery: {
            exports: "$"
        },
        hideshowpassword: ["jquery"],
        jqcountdown: ["jquery"],
        jqcookie: ["jquery"],
        jqchosen: ["jquery"],
        jqcheckbox: ["jquery"],
        jqswipe: ["jquery"],
        jqplaceholders: ["jquery"],
        jqcomplexify: ["jquery"],
        jqappear: ["jquery"],
        jqtimeago: ["jquery"],
        stickykit: ["jquery"],
        mail_validator: ["jquery"],
        jqeasing: ["jquery"],
        jqfancybox: ["jquery"],
        jqcollapser: ["jquery"],
        bootstrapmodal: ["jquery"],
        prizeocheckout: ["jquery"],
        i4go: ["jquery"],
        sly: {
            deps: ["jquery"],
            exports: 'sly'
        }
    }
});

require(["prizeoapplication", "prizeopayment", "prizeosignin", "prizeorafflepage", "prizeodatacollection", "prizeothankyou", "jqfancybox", "i4go"]);

define("main", function(){});

