define('prizeopayment',["jquery"], function ($) {
    "use strict";

    $(function () {

        if (cfg.page === "payment") {
            var isSafari = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1 && navigator.userAgent.indexOf('PhantomJS') == -1;
            var isTest = navigator.userAgent.indexOf('PhantomJS') != -1;

            if (!isSafari && !isTest) {
                $(".chosen-select").chosen({disable_search_threshold: 10, width: '100%'});
            }

        }

        var checkLatin = function() {
          var e = $(this);

          var errorLabel = e.parent().parent().find('span.inp-error');

          var isLatinOnly = (new RegExp("^" + e.attr('pattern') + "$")).test(e.val());

          if(isLatinOnly) errorLabel.addClass("hidden");
          else {
              errorLabel.removeClass("hidden");
              errorLabel.text("Please use simple latin characters");
          }
        };

        $('.js-latin-only').change(checkLatin);
        $('.js-latin-only').keyup(checkLatin);
    });
});

