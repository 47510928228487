define('prizeorafflepage',["jquery","prizeofacebook", "jqchosen", "brightcove", "jqcollapser", "jqfancybox"], function ($) {
    "use strict";

    // Share on facebook
    function share_on_facebook(link, picture, name, description, success, failure) {
        FB.ui(
            {
                method: 'feed',
                name: name,
                link: link,
                picture: picture,
                description: description,
                redirect_uri: link,
                caption: cfg.organization + " campaign"
            },
            function (response) {
                if (response && response.post_id) {
                    // success
                    if(success) success();
                } else {
                    // error
                    if (typeof failure !== "undefined" && failure != null) failure();
                }
            }
        );
    }

    // Share on twitter
    function share_on_twitter(tweetUrl) {
        var width = 575,
            height = 400,
            left = ($(window).width() - width) / 2,
            top = ($(window).height() - height) / 2,
            opts = 'status=1' +
                ',width=' + width +
                ',height=' + height +
                ',top=' + top +
                ',left=' + left;

        window.open(tweetUrl, 'twitter', opts);
    }

    $(function () {
        // Share on Facebook
        $('#fb-thank-you-post').click(function (e) {
            e.preventDefault();

            share_on_facebook(
                cfg.referralLink || cfg.raffle.url,
                cfg.og.image,
                name,
                cfg.og.description
            );
        });

        // share on Twitter
        $('#twitter-thank-you-tweet').click(function (e) {
            e.preventDefault();

            share_on_twitter(cfg.twitter.tweetUrl);
        });
    });

});

