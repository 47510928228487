define('prizeoutils',["jquery","jqcookie"], function () {
    "use strict";

    function findMsgDiv() {
        if($(".modal:visible .message-modal").size() > 0)
            var $msg = $(".modal:visible .message-modal");
        else
            var $msg = $("#message");

        return $msg;
    }

    window.openDeepLink = function(iOSDeeplink, androidDeepLink, fallbackURL) {
        //Check is device is iOS
        var iOS = (navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false );

        if (iOS)
        {
            //If the app is not installed the script will wait for 2sec and redirect to web.
            var loadedAt = +new Date;

            setTimeout(
                function(){
                    if (+new Date - loadedAt < 2000){
                        window.location = fallbackURL;
                    }
                }
                ,25);

            //Try launching the app using URL schemes
            window.open(iOSDeeplink,"_self");
        } else {
            //Launch the website
            window.location = fallbackURL;
        }
    };

    /**
     * Messages object contains all methods for manipulating error/ok/info message.
     *
     * All pages where this page is to be used need to contain <div id="message" style="display: none;"></div>.
     */
    window.messages = {
        showMessage: function (message, type) {

            var $msg = findMsgDiv();

            if($msg.size() == 0) {
              !!Bugsnag && Bugsnag.notify("Messages", "Unable to display message, no <div>, '" + message + "'");
            }

            if($msg.hasClass("react-message")){
                var messageCb = window.react.messagesCallbacks[parseInt($msg.attr("data"))];
                if(messageCb) {
                    messageCb({message: message, type: type.replace("message-", "").toUpperCase()})
                }else{
                    var errorText = "Attempt made to call "+$msg.attr("data")+". callback from react.messagesCallbacks but it does not exist";
                  !!window.Bugsnag && window.Bugsnag.notify(errorText);
                    console.error(errorText);
                }

                // scroll to top on error messages
                if (type == "message-error") {
                    setTimeout(function () {
                        $('html, body').animate({scrollTop: $("body").offset().top}, 300);
                    }, 600);
                }
            }else{
                // hide if visible
                $msg.slideUp(null, function () {
                    // show message
                    $msg.removeClass("message-error message-ok message-dark").addClass("message " + type).html(message).slideDown();

                    // scroll to top on error messages
                    if (type == "message-error") {
                        setTimeout(function () {
                            $('html, body').animate({scrollTop: $("body").offset().top}, 300);
                        }, 600);
                    }
                });
            }
        },
        error: function (message) {
            this.showMessage(message, "message-error");
        },
        ok: function (message) {
            this.showMessage(message, "message-ok");
        },
        loading: function (message) {
            this.showMessage('<span class="ico-m ico-waitPage"></span><p>' + message + "</p>", "");
        },
        info: function (message) {
            this.showMessage(message, "");
        },
        dark: function (message) {
            this.showMessage(message, "message-dark");
        },
        isVisible: function () {
            return findMsgDiv().is(":visible");
        },
        hide: function () {
            $(".modal:visible .message-modal").slideUp();
            $("#message").slideUp();
        }
    };

    // Display messages
    $(function () {
        if(typeof cfg.flash.error !== 'undefined') {
            if(window.messages && window.messages.error){
                window.messages.error(cfg.flash.error);
            }else{
                setTimeout(function(){
                    window.messages.error(cfg.flash.error);
                }, 2500)
            }
        } else if(typeof cfg.flash.ok !== 'undefined') {
            if(window.messages && window.messages.ok){
                window.messages.ok(cfg.flash.ok);
            }else{
                setTimeout(function(){
                    window.messages.ok(cfg.flash.ok);
                }, 2500)
            }
        }
    });

    /**
     * Submits an array of given params to given path using given method.
     * It is handy for submitting forms by POST with JavaScript.
     *
     * Source: http://stackoverflow.com/questions/133925/javascript-post-request-like-a-form-submit
     *
     * @param String path URL to which the form should be submitted
     * @param Object params object of parameters e.g. {"originUrl" : "/"}
     * @param String method GET/POST
     */
    window.postToURL = function (path, params, method) {
        method = method || "post"; // Set method to post by default, if not specified.

        // The rest of this code assumes you are not using a library.
        // It can be made less wordy if you use one.
        var form = document.createElement("form");
        form.setAttribute("method", method);
        form.setAttribute("action", path);

        // append authenticityToken
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", "authenticityToken");
        hiddenField.setAttribute("value", $.cookie("csrftkn"));
        form.appendChild(hiddenField);

        // iterate over given array and append every value
        for (var key in params) {
            if (params.hasOwnProperty(key)) {
                if (params[key] == Array) {
                    // if this item is an array
                    for (var item in params[key]) {
                        // iterate over array items and add them all
                        var hiddenField = document.createElement("input");
                        hiddenField.setAttribute("type", "hidden");
                        hiddenField.setAttribute("name", key + "[]");
                        hiddenField.setAttribute("value", params[key][item]);

                        form.appendChild(hiddenField);
                    }
                } else {
                    // otherwise, if this item is ordinary item
                    var hiddenField = document.createElement("input");
                    hiddenField.setAttribute("type", "hidden");
                    hiddenField.setAttribute("name", key);
                    hiddenField.setAttribute("value", params[key]);

                    form.appendChild(hiddenField);
                }
            }
        }

        document.body.appendChild(form);
        form.submit();
    };

    function populateErrors(form, result_errors) {
        $.each(result_errors, function(name, errors) {
            var input = form.find("input[name=" + escapejQuerySpecial(name) + "]");
            input.addClass("error");
            var parent = input.parent().parent();
            parent.addClass("error");
            var errorElem = parent.children().last();
            errorElem.text(polyglot.t(errors[0]));
            errorElem.removeClass("hidden");
        });
    }

    function populateModifications(form, result_modifications) {
        $.each(result_modifications, function(name, modif) {
            var input = form.find("input[name=" + escapejQuerySpecial(name) + "]");
            input.val(escapejQuerySpecial(modif));
            input.addClass("modification");
            var parent = input.parent().parent();
            parent.addClass("modification");
        });
    }

    window.validateRequiredFields = function(form, show_errors) {
        var failed = false;

        form.find(".js-required").each(function(i, element) {
            var input = $(element);

            if(input.parents('.hidden').length > 0) return;

            if(input.val() == "") {
                if(show_errors) {
                    input.addClass("error");
                    var parent = input.parent().parent();
                    parent.addClass("error");
                    var error_element = parent.find(".inp-error");
                    error_element.text(polyglot.t("validation.generic.required.js"));
                    error_element.removeClass("hidden");
                    error_element.show();
                }

                failed = true;
            }
        });

        return !failed;
    };

    function escapejQuerySpecial(selector) {
        return selector.replace( /(:|\.|\[|\]|,)/g, "\\$1" );
    }

    /*
     * Function handles basic replies from prizeo API:
     *
     * FormReply
     */
    window.ajaxToUrl = function(url, form, formIdentifier, method, success, error, modifications, rawResponse) {
        var xhrFields = {};

        if(url.lastIndexOf("https:", 0) === 0 && document.location.protocol == "http:") {
            //accessing https from http page
//            xhrFields = {withCredentials: true};
        }

        var contentType = "application/x-www-form-urlencoded"; //default
        var data = {};

        if(method == 'get') {
            if(typeof form === 'string') {

                url += '?' + form;
            } else {

                if (url.indexOf('?') == -1) url += '?';
                else url += '&';

                var encoded_form = [];

                for (var key in form) {
                    if (form.hasOwnProperty(key)) {
                        encoded_form.push(encodeURIComponent(key) + "=" + encodeURIComponent(form[key]));
                    }
                }

                url += encoded_form.join('&');
            }
        }

        if(method == 'post') {
            data = $(form).serializeArray();
        } else if(method == 'post-json') {
            contentType = "application/json; charset=utf-8";
            data = JSON.stringify(form);
            method = 'post';
        }


        $.ajax({
            url: url,
            data: data,
            type: method,
            dataType: "json",
            contentType: contentType,
            xhrFields: xhrFields,
            success: function (result) {
                if(rawResponse) {
                    rawResponse(result);
                } else {
                    if (result.status === "error") {
                        if (typeof result.error !== "undefined") messages.error(result.error.message);
                        if (typeof result.errors !== "undefined" && formIdentifier) {
                            populateErrors($(formIdentifier), result.errors);
                        }
                        error(result);
                    } else if (result.status === "modifications") {
                        if (typeof result.modifications !== "undefined" && formIdentifier) populateModifications($(formIdentifier), result.modifications);
                        modifications(result);
                    } else if (result.status && result.status.indexOf("ok") === 0) {
                        success(result)
                    } else if (result.status === "redirect") {
                        window.location = result.redirectTo;
                    }
                }
            },
            error: function(request) {
                if(rawResponse) {
                    rawResponse(request);
                } else {

                    if (request && request.responseJSON && request.responseJSON.error) {
                        messages.error(request.responseJSON.error.message);
                        error(request.responseJSON);
                    } else if (request && request.responseJSON && request.responseJSON.userMessage) {
                        messages.error(request.responseJSON.userMessage);
                        error(request.responseJSON);
                    } else {
                        //network failure?
                        messages.error("An unexpected error has occurred, please try again");
                        error(request);
                    }
                }
            }
        });
    };

// ----------------------------------------------------------
// A short snippet for detecting versions of IE in JavaScript
// without resorting to user-agent sniffing
// ----------------------------------------------------------
// If you're not in IE (or IE version is less than 5) then:
// ie === undefined
// If you're in IE (>=5) then you can determine which version:
// ie === 7; // IE7
// Thus, to detect IE:
// if (ie) {}
// And to detect the version:
// ie === 6 // IE6
// ie > 7 // IE8, IE9 ...
// ie < 9 // Anything less than IE9
// ----------------------------------------------------------

// UPDATE: Now using Live NodeList idea from @jdalton

    var ie = (function () {

        var undef,
            v = 3,
            div = document.createElement('div'),
            all = div.getElementsByTagName('i');

        while (
            div.innerHTML = '<!--[if gt IE ' + (++v) + ']><i></i><![endif]-->',
                all[0]
            ) {
        }

        return v > 4 ? v : undef;

    }());

    /**
     * Prototype that enhances string object and offers .latinise() method.
     * Originally from: http://semplicewebsites.com/removing-accents-javascript
     * Licence not stated, probably public domain.
     *
     * Ex: "ľšč".latinise() -> "lsc"
     */
// Generated from latin_map.pl Sun Jun 20 20:17:18 2010
    var Latinise = {};
    Latinise.latin_map = {
        'Á': 'A', // LATIN CAPITAL LETTER A WITH ACUTE
        'Ă': 'A', // LATIN CAPITAL LETTER A WITH BREVE
        'Ắ': 'A', // LATIN CAPITAL LETTER A WITH BREVE AND ACUTE
        'Ặ': 'A', // LATIN CAPITAL LETTER A WITH BREVE AND DOT BELOW
        'Ằ': 'A', // LATIN CAPITAL LETTER A WITH BREVE AND GRAVE
        'Ẳ': 'A', // LATIN CAPITAL LETTER A WITH BREVE AND HOOK ABOVE
        'Ẵ': 'A', // LATIN CAPITAL LETTER A WITH BREVE AND TILDE
        'Ǎ': 'A', // LATIN CAPITAL LETTER A WITH CARON
        'Â': 'A', // LATIN CAPITAL LETTER A WITH CIRCUMFLEX
        'Ấ': 'A', // LATIN CAPITAL LETTER A WITH CIRCUMFLEX AND ACUTE
        'Ậ': 'A', // LATIN CAPITAL LETTER A WITH CIRCUMFLEX AND DOT BELOW
        'Ầ': 'A', // LATIN CAPITAL LETTER A WITH CIRCUMFLEX AND GRAVE
        'Ẩ': 'A', // LATIN CAPITAL LETTER A WITH CIRCUMFLEX AND HOOK ABOVE
        'Ẫ': 'A', // LATIN CAPITAL LETTER A WITH CIRCUMFLEX AND TILDE
        'Ä': 'A', // LATIN CAPITAL LETTER A WITH DIAERESIS
        'Ǟ': 'A', // LATIN CAPITAL LETTER A WITH DIAERESIS AND MACRON
        'Ȧ': 'A', // LATIN CAPITAL LETTER A WITH DOT ABOVE
        'Ǡ': 'A', // LATIN CAPITAL LETTER A WITH DOT ABOVE AND MACRON
        'Ạ': 'A', // LATIN CAPITAL LETTER A WITH DOT BELOW
        'Ȁ': 'A', // LATIN CAPITAL LETTER A WITH DOUBLE GRAVE
        'À': 'A', // LATIN CAPITAL LETTER A WITH GRAVE
        'Ả': 'A', // LATIN CAPITAL LETTER A WITH HOOK ABOVE
        'Ȃ': 'A', // LATIN CAPITAL LETTER A WITH INVERTED BREVE
        'Ā': 'A', // LATIN CAPITAL LETTER A WITH MACRON
        'Ą': 'A', // LATIN CAPITAL LETTER A WITH OGONEK
        'Å': 'A', // LATIN CAPITAL LETTER A WITH RING ABOVE
        'Ǻ': 'A', // LATIN CAPITAL LETTER A WITH RING ABOVE AND ACUTE
        'Ḁ': 'A', // LATIN CAPITAL LETTER A WITH RING BELOW
        'Ⱥ': 'A', // LATIN CAPITAL LETTER A WITH STROKE
        'Ã': 'A', // LATIN CAPITAL LETTER A WITH TILDE
        'Ꜳ': 'AA', // LATIN CAPITAL LETTER AA
        'Æ': 'AE', // LATIN CAPITAL LETTER AE
        'Ǽ': 'AE', // LATIN CAPITAL LETTER AE WITH ACUTE
        'Ǣ': 'AE', // LATIN CAPITAL LETTER AE WITH MACRON
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER AFRICAN D' (Ɖ)
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER ALPHA' (Ɑ)
        'Ꜵ': 'AO', // LATIN CAPITAL LETTER AO
        'Ꜷ': 'AU', // LATIN CAPITAL LETTER AU
        'Ꜹ': 'AV', // LATIN CAPITAL LETTER AV
        'Ꜻ': 'AV', // LATIN CAPITAL LETTER AV WITH HORIZONTAL BAR
        'Ꜽ': 'AY', // LATIN CAPITAL LETTER AY
        'Ḃ': 'B', // LATIN CAPITAL LETTER B WITH DOT ABOVE
        'Ḅ': 'B', // LATIN CAPITAL LETTER B WITH DOT BELOW
        'Ɓ': 'B', // LATIN CAPITAL LETTER B WITH HOOK
        'Ḇ': 'B', // LATIN CAPITAL LETTER B WITH LINE BELOW
        'Ƀ': 'B', // LATIN CAPITAL LETTER B WITH STROKE
        'Ƃ': 'B', // LATIN CAPITAL LETTER B WITH TOPBAR
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER BROKEN L' (Ꝇ)
        'Ć': 'C', // LATIN CAPITAL LETTER C WITH ACUTE
        'Č': 'C', // LATIN CAPITAL LETTER C WITH CARON
        'Ç': 'C', // LATIN CAPITAL LETTER C WITH CEDILLA
        'Ḉ': 'C', // LATIN CAPITAL LETTER C WITH CEDILLA AND ACUTE
        'Ĉ': 'C', // LATIN CAPITAL LETTER C WITH CIRCUMFLEX
        'Ċ': 'C', // LATIN CAPITAL LETTER C WITH DOT ABOVE
        'Ƈ': 'C', // LATIN CAPITAL LETTER C WITH HOOK
        'Ȼ': 'C', // LATIN CAPITAL LETTER C WITH STROKE
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER CON' (Ꝯ)
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER CUATRILLO' (Ꜭ)
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER CUATRILLO WITH COMMA' (Ꜯ)
        'Ď': 'D', // LATIN CAPITAL LETTER D WITH CARON
        'Ḑ': 'D', // LATIN CAPITAL LETTER D WITH CEDILLA
        'Ḓ': 'D', // LATIN CAPITAL LETTER D WITH CIRCUMFLEX BELOW
        'Ḋ': 'D', // LATIN CAPITAL LETTER D WITH DOT ABOVE
        'Ḍ': 'D', // LATIN CAPITAL LETTER D WITH DOT BELOW
        'Ɗ': 'D', // LATIN CAPITAL LETTER D WITH HOOK
        'Ḏ': 'D', // LATIN CAPITAL LETTER D WITH LINE BELOW
        'ǲ': 'D', // LATIN CAPITAL LETTER D WITH SMALL LETTER Z
        'ǅ': 'D', // LATIN CAPITAL LETTER D WITH SMALL LETTER Z WITH CARON
        'Đ': 'D', // LATIN CAPITAL LETTER D WITH STROKE
        'Ƌ': 'D', // LATIN CAPITAL LETTER D WITH TOPBAR
        'Ǳ': 'DZ', // LATIN CAPITAL LETTER DZ
        'Ǆ': 'DZ', // LATIN CAPITAL LETTER DZ WITH CARON
        'É': 'E', // LATIN CAPITAL LETTER E WITH ACUTE
        'Ĕ': 'E', // LATIN CAPITAL LETTER E WITH BREVE
        'Ě': 'E', // LATIN CAPITAL LETTER E WITH CARON
        'Ȩ': 'E', // LATIN CAPITAL LETTER E WITH CEDILLA
        'Ḝ': 'E', // LATIN CAPITAL LETTER E WITH CEDILLA AND BREVE
        'Ê': 'E', // LATIN CAPITAL LETTER E WITH CIRCUMFLEX
        'Ế': 'E', // LATIN CAPITAL LETTER E WITH CIRCUMFLEX AND ACUTE
        'Ệ': 'E', // LATIN CAPITAL LETTER E WITH CIRCUMFLEX AND DOT BELOW
        'Ề': 'E', // LATIN CAPITAL LETTER E WITH CIRCUMFLEX AND GRAVE
        'Ể': 'E', // LATIN CAPITAL LETTER E WITH CIRCUMFLEX AND HOOK ABOVE
        'Ễ': 'E', // LATIN CAPITAL LETTER E WITH CIRCUMFLEX AND TILDE
        'Ḙ': 'E', // LATIN CAPITAL LETTER E WITH CIRCUMFLEX BELOW
        'Ë': 'E', // LATIN CAPITAL LETTER E WITH DIAERESIS
        'Ė': 'E', // LATIN CAPITAL LETTER E WITH DOT ABOVE
        'Ẹ': 'E', // LATIN CAPITAL LETTER E WITH DOT BELOW
        'Ȅ': 'E', // LATIN CAPITAL LETTER E WITH DOUBLE GRAVE
        'È': 'E', // LATIN CAPITAL LETTER E WITH GRAVE
        'Ẻ': 'E', // LATIN CAPITAL LETTER E WITH HOOK ABOVE
        'Ȇ': 'E', // LATIN CAPITAL LETTER E WITH INVERTED BREVE
        'Ē': 'E', // LATIN CAPITAL LETTER E WITH MACRON
        'Ḗ': 'E', // LATIN CAPITAL LETTER E WITH MACRON AND ACUTE
        'Ḕ': 'E', // LATIN CAPITAL LETTER E WITH MACRON AND GRAVE
        'Ę': 'E', // LATIN CAPITAL LETTER E WITH OGONEK
        'Ɇ': 'E', // LATIN CAPITAL LETTER E WITH STROKE
        'Ẽ': 'E', // LATIN CAPITAL LETTER E WITH TILDE
        'Ḛ': 'E', // LATIN CAPITAL LETTER E WITH TILDE BELOW
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER EGYPTOLOGICAL AIN' (Ꜥ)
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER EGYPTOLOGICAL ALEF' (Ꜣ)
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER ENG' (Ŋ)
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER ESH' (Ʃ)
        'Ꝫ': 'ET', // LATIN CAPITAL LETTER ET
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER ETH' (Ð)
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER EZH' (Ʒ)
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER EZH REVERSED' (Ƹ)
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER EZH WITH CARON' (Ǯ)
        'Ḟ': 'F', // LATIN CAPITAL LETTER F WITH DOT ABOVE
        'Ƒ': 'F', // LATIN CAPITAL LETTER F WITH HOOK
        'Ǵ': 'G', // LATIN CAPITAL LETTER G WITH ACUTE
        'Ğ': 'G', // LATIN CAPITAL LETTER G WITH BREVE
        'Ǧ': 'G', // LATIN CAPITAL LETTER G WITH CARON
        'Ģ': 'G', // LATIN CAPITAL LETTER G WITH CEDILLA
        'Ĝ': 'G', // LATIN CAPITAL LETTER G WITH CIRCUMFLEX
        'Ġ': 'G', // LATIN CAPITAL LETTER G WITH DOT ABOVE
        'Ɠ': 'G', // LATIN CAPITAL LETTER G WITH HOOK
        'Ḡ': 'G', // LATIN CAPITAL LETTER G WITH MACRON
        'Ǥ': 'G', // LATIN CAPITAL LETTER G WITH STROKE
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER GAMMA' (Ɣ)
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER GLOTTAL STOP' (Ɂ)
        'Ḫ': 'H', // LATIN CAPITAL LETTER H WITH BREVE BELOW
        'Ȟ': 'H', // LATIN CAPITAL LETTER H WITH CARON
        'Ḩ': 'H', // LATIN CAPITAL LETTER H WITH CEDILLA
        'Ĥ': 'H', // LATIN CAPITAL LETTER H WITH CIRCUMFLEX
        'Ⱨ': 'H', // LATIN CAPITAL LETTER H WITH DESCENDER
        'Ḧ': 'H', // LATIN CAPITAL LETTER H WITH DIAERESIS
        'Ḣ': 'H', // LATIN CAPITAL LETTER H WITH DOT ABOVE
        'Ḥ': 'H', // LATIN CAPITAL LETTER H WITH DOT BELOW
        'Ħ': 'H', // LATIN CAPITAL LETTER H WITH STROKE
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER HALF H' (Ⱶ)
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER HENG' (Ꜧ)
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER HWAIR' (Ƕ)
        'Í': 'I', // LATIN CAPITAL LETTER I WITH ACUTE
        'Ĭ': 'I', // LATIN CAPITAL LETTER I WITH BREVE
        'Ǐ': 'I', // LATIN CAPITAL LETTER I WITH CARON
        'Î': 'I', // LATIN CAPITAL LETTER I WITH CIRCUMFLEX
        'Ï': 'I', // LATIN CAPITAL LETTER I WITH DIAERESIS
        'Ḯ': 'I', // LATIN CAPITAL LETTER I WITH DIAERESIS AND ACUTE
        'İ': 'I', // LATIN CAPITAL LETTER I WITH DOT ABOVE
        'Ị': 'I', // LATIN CAPITAL LETTER I WITH DOT BELOW
        'Ȉ': 'I', // LATIN CAPITAL LETTER I WITH DOUBLE GRAVE
        'Ì': 'I', // LATIN CAPITAL LETTER I WITH GRAVE
        'Ỉ': 'I', // LATIN CAPITAL LETTER I WITH HOOK ABOVE
        'Ȋ': 'I', // LATIN CAPITAL LETTER I WITH INVERTED BREVE
        'Ī': 'I', // LATIN CAPITAL LETTER I WITH MACRON
        'Į': 'I', // LATIN CAPITAL LETTER I WITH OGONEK
        'Ɨ': 'I', // LATIN CAPITAL LETTER I WITH STROKE
        'Ĩ': 'I', // LATIN CAPITAL LETTER I WITH TILDE
        'Ḭ': 'I', // LATIN CAPITAL LETTER I WITH TILDE BELOW
        'Ꝺ': 'D', // LATIN CAPITAL LETTER INSULAR D
        'Ꝼ': 'F', // LATIN CAPITAL LETTER INSULAR F
        'Ᵹ': 'G', // LATIN CAPITAL LETTER INSULAR G
        'Ꞃ': 'R', // LATIN CAPITAL LETTER INSULAR R
        'Ꞅ': 'S', // LATIN CAPITAL LETTER INSULAR S
        'Ꞇ': 'T', // LATIN CAPITAL LETTER INSULAR T
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER IOTA' (Ɩ)
        'Ꝭ': 'IS', // LATIN CAPITAL LETTER IS
        'Ĵ': 'J', // LATIN CAPITAL LETTER J WITH CIRCUMFLEX
        'Ɉ': 'J', // LATIN CAPITAL LETTER J WITH STROKE
        'Ḱ': 'K', // LATIN CAPITAL LETTER K WITH ACUTE
        'Ǩ': 'K', // LATIN CAPITAL LETTER K WITH CARON
        'Ķ': 'K', // LATIN CAPITAL LETTER K WITH CEDILLA
        'Ⱪ': 'K', // LATIN CAPITAL LETTER K WITH DESCENDER
        'Ꝃ': 'K', // LATIN CAPITAL LETTER K WITH DIAGONAL STROKE
        'Ḳ': 'K', // LATIN CAPITAL LETTER K WITH DOT BELOW
        'Ƙ': 'K', // LATIN CAPITAL LETTER K WITH HOOK
        'Ḵ': 'K', // LATIN CAPITAL LETTER K WITH LINE BELOW
        'Ꝁ': 'K', // LATIN CAPITAL LETTER K WITH STROKE
        'Ꝅ': 'K', // LATIN CAPITAL LETTER K WITH STROKE AND DIAGONAL STROKE
        'Ĺ': 'L', // LATIN CAPITAL LETTER L WITH ACUTE
        'Ƚ': 'L', // LATIN CAPITAL LETTER L WITH BAR
        'Ľ': 'L', // LATIN CAPITAL LETTER L WITH CARON
        'Ļ': 'L', // LATIN CAPITAL LETTER L WITH CEDILLA
        'Ḽ': 'L', // LATIN CAPITAL LETTER L WITH CIRCUMFLEX BELOW
        'Ḷ': 'L', // LATIN CAPITAL LETTER L WITH DOT BELOW
        'Ḹ': 'L', // LATIN CAPITAL LETTER L WITH DOT BELOW AND MACRON
        'Ⱡ': 'L', // LATIN CAPITAL LETTER L WITH DOUBLE BAR
        'Ꝉ': 'L', // LATIN CAPITAL LETTER L WITH HIGH STROKE
        'Ḻ': 'L', // LATIN CAPITAL LETTER L WITH LINE BELOW
        'Ŀ': 'L', // LATIN CAPITAL LETTER L WITH MIDDLE DOT
        'Ɫ': 'L', // LATIN CAPITAL LETTER L WITH MIDDLE TILDE
        'ǈ': 'L', // LATIN CAPITAL LETTER L WITH SMALL LETTER J
        'Ł': 'L', // LATIN CAPITAL LETTER L WITH STROKE
        'Ǉ': 'LJ', // LATIN CAPITAL LETTER LJ
        'Ḿ': 'M', // LATIN CAPITAL LETTER M WITH ACUTE
        'Ṁ': 'M', // LATIN CAPITAL LETTER M WITH DOT ABOVE
        'Ṃ': 'M', // LATIN CAPITAL LETTER M WITH DOT BELOW
        'Ɱ': 'M', // LATIN CAPITAL LETTER M WITH HOOK
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER MIDDLE-WELSH LL' (Ỻ)
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER MIDDLE-WELSH V' (Ỽ)
        'Ń': 'N', // LATIN CAPITAL LETTER N WITH ACUTE
        'Ň': 'N', // LATIN CAPITAL LETTER N WITH CARON
        'Ņ': 'N', // LATIN CAPITAL LETTER N WITH CEDILLA
        'Ṋ': 'N', // LATIN CAPITAL LETTER N WITH CIRCUMFLEX BELOW
        'Ṅ': 'N', // LATIN CAPITAL LETTER N WITH DOT ABOVE
        'Ṇ': 'N', // LATIN CAPITAL LETTER N WITH DOT BELOW
        'Ǹ': 'N', // LATIN CAPITAL LETTER N WITH GRAVE
        'Ɲ': 'N', // LATIN CAPITAL LETTER N WITH LEFT HOOK
        'Ṉ': 'N', // LATIN CAPITAL LETTER N WITH LINE BELOW
        'Ƞ': 'N', // LATIN CAPITAL LETTER N WITH LONG RIGHT LEG
        'ǋ': 'N', // LATIN CAPITAL LETTER N WITH SMALL LETTER J
        'Ñ': 'N', // LATIN CAPITAL LETTER N WITH TILDE
        'Ǌ': 'NJ', // LATIN CAPITAL LETTER NJ
        'Ó': 'O', // LATIN CAPITAL LETTER O WITH ACUTE
        'Ŏ': 'O', // LATIN CAPITAL LETTER O WITH BREVE
        'Ǒ': 'O', // LATIN CAPITAL LETTER O WITH CARON
        'Ô': 'O', // LATIN CAPITAL LETTER O WITH CIRCUMFLEX
        'Ố': 'O', // LATIN CAPITAL LETTER O WITH CIRCUMFLEX AND ACUTE
        'Ộ': 'O', // LATIN CAPITAL LETTER O WITH CIRCUMFLEX AND DOT BELOW
        'Ồ': 'O', // LATIN CAPITAL LETTER O WITH CIRCUMFLEX AND GRAVE
        'Ổ': 'O', // LATIN CAPITAL LETTER O WITH CIRCUMFLEX AND HOOK ABOVE
        'Ỗ': 'O', // LATIN CAPITAL LETTER O WITH CIRCUMFLEX AND TILDE
        'Ö': 'O', // LATIN CAPITAL LETTER O WITH DIAERESIS
        'Ȫ': 'O', // LATIN CAPITAL LETTER O WITH DIAERESIS AND MACRON
        'Ȯ': 'O', // LATIN CAPITAL LETTER O WITH DOT ABOVE
        'Ȱ': 'O', // LATIN CAPITAL LETTER O WITH DOT ABOVE AND MACRON
        'Ọ': 'O', // LATIN CAPITAL LETTER O WITH DOT BELOW
        'Ő': 'O', // LATIN CAPITAL LETTER O WITH DOUBLE ACUTE
        'Ȍ': 'O', // LATIN CAPITAL LETTER O WITH DOUBLE GRAVE
        'Ò': 'O', // LATIN CAPITAL LETTER O WITH GRAVE
        'Ỏ': 'O', // LATIN CAPITAL LETTER O WITH HOOK ABOVE
        'Ơ': 'O', // LATIN CAPITAL LETTER O WITH HORN
        'Ớ': 'O', // LATIN CAPITAL LETTER O WITH HORN AND ACUTE
        'Ợ': 'O', // LATIN CAPITAL LETTER O WITH HORN AND DOT BELOW
        'Ờ': 'O', // LATIN CAPITAL LETTER O WITH HORN AND GRAVE
        'Ở': 'O', // LATIN CAPITAL LETTER O WITH HORN AND HOOK ABOVE
        'Ỡ': 'O', // LATIN CAPITAL LETTER O WITH HORN AND TILDE
        'Ȏ': 'O', // LATIN CAPITAL LETTER O WITH INVERTED BREVE
        'Ꝋ': 'O', // LATIN CAPITAL LETTER O WITH LONG STROKE OVERLAY
        'Ꝍ': 'O', // LATIN CAPITAL LETTER O WITH LOOP
        'Ō': 'O', // LATIN CAPITAL LETTER O WITH MACRON
        'Ṓ': 'O', // LATIN CAPITAL LETTER O WITH MACRON AND ACUTE
        'Ṑ': 'O', // LATIN CAPITAL LETTER O WITH MACRON AND GRAVE
        'Ɵ': 'O', // LATIN CAPITAL LETTER O WITH MIDDLE TILDE
        'Ǫ': 'O', // LATIN CAPITAL LETTER O WITH OGONEK
        'Ǭ': 'O', // LATIN CAPITAL LETTER O WITH OGONEK AND MACRON
        'Ø': 'O', // LATIN CAPITAL LETTER O WITH STROKE
        'Ǿ': 'O', // LATIN CAPITAL LETTER O WITH STROKE AND ACUTE
        'Õ': 'O', // LATIN CAPITAL LETTER O WITH TILDE
        'Ṍ': 'O', // LATIN CAPITAL LETTER O WITH TILDE AND ACUTE
        'Ṏ': 'O', // LATIN CAPITAL LETTER O WITH TILDE AND DIAERESIS
        'Ȭ': 'O', // LATIN CAPITAL LETTER O WITH TILDE AND MACRON
        'Ƣ': 'OI', // LATIN CAPITAL LETTER OI
        'Ꝏ': 'OO', // LATIN CAPITAL LETTER OO
        'Ɛ': 'E', // LATIN CAPITAL LETTER OPEN E
        'Ɔ': 'O', // LATIN CAPITAL LETTER OPEN O
        'Ȣ': 'OU', // LATIN CAPITAL LETTER OU
        'Ṕ': 'P', // LATIN CAPITAL LETTER P WITH ACUTE
        'Ṗ': 'P', // LATIN CAPITAL LETTER P WITH DOT ABOVE
        'Ꝓ': 'P', // LATIN CAPITAL LETTER P WITH FLOURISH
        'Ƥ': 'P', // LATIN CAPITAL LETTER P WITH HOOK
        'Ꝕ': 'P', // LATIN CAPITAL LETTER P WITH SQUIRREL TAIL
        'Ᵽ': 'P', // LATIN CAPITAL LETTER P WITH STROKE
        'Ꝑ': 'P', // LATIN CAPITAL LETTER P WITH STROKE THROUGH DESCENDER
        'Ꝙ': 'Q', // LATIN CAPITAL LETTER Q WITH DIAGONAL STROKE
        'Ꝗ': 'Q', // LATIN CAPITAL LETTER Q WITH STROKE THROUGH DESCENDER
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER R ROTUNDA' (Ꝛ)
        'Ŕ': 'R', // LATIN CAPITAL LETTER R WITH ACUTE
        'Ř': 'R', // LATIN CAPITAL LETTER R WITH CARON
        'Ŗ': 'R', // LATIN CAPITAL LETTER R WITH CEDILLA
        'Ṙ': 'R', // LATIN CAPITAL LETTER R WITH DOT ABOVE
        'Ṛ': 'R', // LATIN CAPITAL LETTER R WITH DOT BELOW
        'Ṝ': 'R', // LATIN CAPITAL LETTER R WITH DOT BELOW AND MACRON
        'Ȑ': 'R', // LATIN CAPITAL LETTER R WITH DOUBLE GRAVE
        'Ȓ': 'R', // LATIN CAPITAL LETTER R WITH INVERTED BREVE
        'Ṟ': 'R', // LATIN CAPITAL LETTER R WITH LINE BELOW
        'Ɍ': 'R', // LATIN CAPITAL LETTER R WITH STROKE
        'Ɽ': 'R', // LATIN CAPITAL LETTER R WITH TAIL
        'Ꜿ': 'C', // LATIN CAPITAL LETTER REVERSED C WITH DOT
        'Ǝ': 'E', // LATIN CAPITAL LETTER REVERSED E
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER RUM ROTUNDA' (Ꝝ)
        'Ś': 'S', // LATIN CAPITAL LETTER S WITH ACUTE
        'Ṥ': 'S', // LATIN CAPITAL LETTER S WITH ACUTE AND DOT ABOVE
        'Š': 'S', // LATIN CAPITAL LETTER S WITH CARON
        'Ṧ': 'S', // LATIN CAPITAL LETTER S WITH CARON AND DOT ABOVE
        'Ş': 'S', // LATIN CAPITAL LETTER S WITH CEDILLA
        'Ŝ': 'S', // LATIN CAPITAL LETTER S WITH CIRCUMFLEX
        'Ș': 'S', // LATIN CAPITAL LETTER S WITH COMMA BELOW
        'Ṡ': 'S', // LATIN CAPITAL LETTER S WITH DOT ABOVE
        'Ṣ': 'S', // LATIN CAPITAL LETTER S WITH DOT BELOW
        'Ṩ': 'S', // LATIN CAPITAL LETTER S WITH DOT BELOW AND DOT ABOVE
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER SALTILLO' (Ꞌ)
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER SCHWA' (Ə)
        'ẞ': 'SS', // LATIN CAPITAL LETTER SHARP S
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER SMALL Q WITH HOOK TAIL' (Ɋ)
        'Ť': 'T', // LATIN CAPITAL LETTER T WITH CARON
        'Ţ': 'T', // LATIN CAPITAL LETTER T WITH CEDILLA
        'Ṱ': 'T', // LATIN CAPITAL LETTER T WITH CIRCUMFLEX BELOW
        'Ț': 'T', // LATIN CAPITAL LETTER T WITH COMMA BELOW
        'Ⱦ': 'T', // LATIN CAPITAL LETTER T WITH DIAGONAL STROKE
        'Ṫ': 'T', // LATIN CAPITAL LETTER T WITH DOT ABOVE
        'Ṭ': 'T', // LATIN CAPITAL LETTER T WITH DOT BELOW
        'Ƭ': 'T', // LATIN CAPITAL LETTER T WITH HOOK
        'Ṯ': 'T', // LATIN CAPITAL LETTER T WITH LINE BELOW
        'Ʈ': 'T', // LATIN CAPITAL LETTER T WITH RETROFLEX HOOK
        'Ŧ': 'T', // LATIN CAPITAL LETTER T WITH STROKE
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER THORN' (Þ)
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER THORN WITH STROKE' (Ꝥ)
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER THORN WITH STROKE THROUGH DESCENDER' (Ꝧ)
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER TONE FIVE' (Ƽ)
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER TONE SIX' (Ƅ)
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER TONE TWO' (Ƨ)
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER TRESILLO' (Ꜫ)
        'Ɐ': 'A', // LATIN CAPITAL LETTER TURNED A
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER TURNED INSULAR G' (Ꝿ)
        'Ꞁ': 'L', // LATIN CAPITAL LETTER TURNED L
        'Ɯ': 'M', // LATIN CAPITAL LETTER TURNED M
        'Ʌ': 'V', // LATIN CAPITAL LETTER TURNED V
        'Ꜩ': 'TZ', // LATIN CAPITAL LETTER TZ
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER U BAR' (Ʉ)
        'Ú': 'U', // LATIN CAPITAL LETTER U WITH ACUTE
        'Ŭ': 'U', // LATIN CAPITAL LETTER U WITH BREVE
        'Ǔ': 'U', // LATIN CAPITAL LETTER U WITH CARON
        'Û': 'U', // LATIN CAPITAL LETTER U WITH CIRCUMFLEX
        'Ṷ': 'U', // LATIN CAPITAL LETTER U WITH CIRCUMFLEX BELOW
        'Ü': 'U', // LATIN CAPITAL LETTER U WITH DIAERESIS
        'Ǘ': 'U', // LATIN CAPITAL LETTER U WITH DIAERESIS AND ACUTE
        'Ǚ': 'U', // LATIN CAPITAL LETTER U WITH DIAERESIS AND CARON
        'Ǜ': 'U', // LATIN CAPITAL LETTER U WITH DIAERESIS AND GRAVE
        'Ǖ': 'U', // LATIN CAPITAL LETTER U WITH DIAERESIS AND MACRON
        'Ṳ': 'U', // LATIN CAPITAL LETTER U WITH DIAERESIS BELOW
        'Ụ': 'U', // LATIN CAPITAL LETTER U WITH DOT BELOW
        'Ű': 'U', // LATIN CAPITAL LETTER U WITH DOUBLE ACUTE
        'Ȕ': 'U', // LATIN CAPITAL LETTER U WITH DOUBLE GRAVE
        'Ù': 'U', // LATIN CAPITAL LETTER U WITH GRAVE
        'Ủ': 'U', // LATIN CAPITAL LETTER U WITH HOOK ABOVE
        'Ư': 'U', // LATIN CAPITAL LETTER U WITH HORN
        'Ứ': 'U', // LATIN CAPITAL LETTER U WITH HORN AND ACUTE
        'Ự': 'U', // LATIN CAPITAL LETTER U WITH HORN AND DOT BELOW
        'Ừ': 'U', // LATIN CAPITAL LETTER U WITH HORN AND GRAVE
        'Ử': 'U', // LATIN CAPITAL LETTER U WITH HORN AND HOOK ABOVE
        'Ữ': 'U', // LATIN CAPITAL LETTER U WITH HORN AND TILDE
        'Ȗ': 'U', // LATIN CAPITAL LETTER U WITH INVERTED BREVE
        'Ū': 'U', // LATIN CAPITAL LETTER U WITH MACRON
        'Ṻ': 'U', // LATIN CAPITAL LETTER U WITH MACRON AND DIAERESIS
        'Ų': 'U', // LATIN CAPITAL LETTER U WITH OGONEK
        'Ů': 'U', // LATIN CAPITAL LETTER U WITH RING ABOVE
        'Ũ': 'U', // LATIN CAPITAL LETTER U WITH TILDE
        'Ṹ': 'U', // LATIN CAPITAL LETTER U WITH TILDE AND ACUTE
        'Ṵ': 'U', // LATIN CAPITAL LETTER U WITH TILDE BELOW
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER UPSILON' (Ʊ)
        'Ꝟ': 'V', // LATIN CAPITAL LETTER V WITH DIAGONAL STROKE
        'Ṿ': 'V', // LATIN CAPITAL LETTER V WITH DOT BELOW
        'Ʋ': 'V', // LATIN CAPITAL LETTER V WITH HOOK
        'Ṽ': 'V', // LATIN CAPITAL LETTER V WITH TILDE
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER VEND' (Ꝩ)
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER VISIGOTHIC Z' (Ꝣ)
        'Ꝡ': 'VY', // LATIN CAPITAL LETTER VY
        'Ẃ': 'W', // LATIN CAPITAL LETTER W WITH ACUTE
        'Ŵ': 'W', // LATIN CAPITAL LETTER W WITH CIRCUMFLEX
        'Ẅ': 'W', // LATIN CAPITAL LETTER W WITH DIAERESIS
        'Ẇ': 'W', // LATIN CAPITAL LETTER W WITH DOT ABOVE
        'Ẉ': 'W', // LATIN CAPITAL LETTER W WITH DOT BELOW
        'Ẁ': 'W', // LATIN CAPITAL LETTER W WITH GRAVE
        'Ⱳ': 'W', // LATIN CAPITAL LETTER W WITH HOOK
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER WYNN' (Ƿ)
        'Ẍ': 'X', // LATIN CAPITAL LETTER X WITH DIAERESIS
        'Ẋ': 'X', // LATIN CAPITAL LETTER X WITH DOT ABOVE
        'Ý': 'Y', // LATIN CAPITAL LETTER Y WITH ACUTE
        'Ŷ': 'Y', // LATIN CAPITAL LETTER Y WITH CIRCUMFLEX
        'Ÿ': 'Y', // LATIN CAPITAL LETTER Y WITH DIAERESIS
        'Ẏ': 'Y', // LATIN CAPITAL LETTER Y WITH DOT ABOVE
        'Ỵ': 'Y', // LATIN CAPITAL LETTER Y WITH DOT BELOW
        'Ỳ': 'Y', // LATIN CAPITAL LETTER Y WITH GRAVE
        'Ƴ': 'Y', // LATIN CAPITAL LETTER Y WITH HOOK
        'Ỷ': 'Y', // LATIN CAPITAL LETTER Y WITH HOOK ABOVE
        'Ỿ': 'Y', // LATIN CAPITAL LETTER Y WITH LOOP
        'Ȳ': 'Y', // LATIN CAPITAL LETTER Y WITH MACRON
        'Ɏ': 'Y', // LATIN CAPITAL LETTER Y WITH STROKE
        'Ỹ': 'Y', // LATIN CAPITAL LETTER Y WITH TILDE
// CANNOT FIND APPROXIMATION FOR 'LATIN CAPITAL LETTER YOGH' (Ȝ)
        'Ź': 'Z', // LATIN CAPITAL LETTER Z WITH ACUTE
        'Ž': 'Z', // LATIN CAPITAL LETTER Z WITH CARON
        'Ẑ': 'Z', // LATIN CAPITAL LETTER Z WITH CIRCUMFLEX
        'Ⱬ': 'Z', // LATIN CAPITAL LETTER Z WITH DESCENDER
        'Ż': 'Z', // LATIN CAPITAL LETTER Z WITH DOT ABOVE
        'Ẓ': 'Z', // LATIN CAPITAL LETTER Z WITH DOT BELOW
        'Ȥ': 'Z', // LATIN CAPITAL LETTER Z WITH HOOK
        'Ẕ': 'Z', // LATIN CAPITAL LETTER Z WITH LINE BELOW
        'Ƶ': 'Z', // LATIN CAPITAL LETTER Z WITH STROKE
        'Ĳ': 'IJ', // LATIN CAPITAL LIGATURE IJ
        'Œ': 'OE', // LATIN CAPITAL LIGATURE OE
// CANNOT FIND APPROXIMATION FOR 'LATIN CROSS' (✝)
// CANNOT FIND APPROXIMATION FOR 'LATIN EPIGRAPHIC LETTER ARCHAIC M' (ꟿ)
// CANNOT FIND APPROXIMATION FOR 'LATIN EPIGRAPHIC LETTER I LONGA' (ꟾ)
// CANNOT FIND APPROXIMATION FOR 'LATIN EPIGRAPHIC LETTER INVERTED M' (ꟽ)
// CANNOT FIND APPROXIMATION FOR 'LATIN EPIGRAPHIC LETTER REVERSED F' (ꟻ)
// CANNOT FIND APPROXIMATION FOR 'LATIN EPIGRAPHIC LETTER REVERSED P' (ꟼ)
// CANNOT FIND APPROXIMATION FOR 'LATIN LETTER AIN' (ᴥ)
// CANNOT FIND APPROXIMATION FOR 'LATIN LETTER ALVEOLAR CLICK' (ǂ)
// CANNOT FIND APPROXIMATION FOR 'LATIN LETTER BIDENTAL PERCUSSIVE' (ʭ)
// CANNOT FIND APPROXIMATION FOR 'LATIN LETTER BILABIAL CLICK' (ʘ)
// CANNOT FIND APPROXIMATION FOR 'LATIN LETTER BILABIAL PERCUSSIVE' (ʬ)
// CANNOT FIND APPROXIMATION FOR 'LATIN LETTER DENTAL CLICK' (ǀ)
// CANNOT FIND APPROXIMATION FOR 'LATIN LETTER GLOTTAL STOP' (ʔ)
// CANNOT FIND APPROXIMATION FOR 'LATIN LETTER GLOTTAL STOP WITH STROKE' (ʡ)
// CANNOT FIND APPROXIMATION FOR 'LATIN LETTER INVERTED GLOTTAL STOP' (ʖ)
// CANNOT FIND APPROXIMATION FOR 'LATIN LETTER INVERTED GLOTTAL STOP WITH STROKE' (ƾ)
// CANNOT FIND APPROXIMATION FOR 'LATIN LETTER LATERAL CLICK' (ǁ)
// CANNOT FIND APPROXIMATION FOR 'LATIN LETTER PHARYNGEAL VOICED FRICATIVE' (ʕ)
// CANNOT FIND APPROXIMATION FOR 'LATIN LETTER RETROFLEX CLICK' (ǃ)
// CANNOT FIND APPROXIMATION FOR 'LATIN LETTER REVERSED ESH LOOP' (ƪ)
// CANNOT FIND APPROXIMATION FOR 'LATIN LETTER REVERSED GLOTTAL STOP WITH STROKE' (ʢ)
        'ᴀ': 'A', // LATIN LETTER SMALL CAPITAL A
        'ᴁ': 'AE', // LATIN LETTER SMALL CAPITAL AE
        'ʙ': 'B', // LATIN LETTER SMALL CAPITAL B
        'ᴃ': 'B', // LATIN LETTER SMALL CAPITAL BARRED B
        'ᴄ': 'C', // LATIN LETTER SMALL CAPITAL C
        'ᴅ': 'D', // LATIN LETTER SMALL CAPITAL D
        'ᴇ': 'E', // LATIN LETTER SMALL CAPITAL E
// CANNOT FIND APPROXIMATION FOR 'LATIN LETTER SMALL CAPITAL ETH' (ᴆ)
// CANNOT FIND APPROXIMATION FOR 'LATIN LETTER SMALL CAPITAL EZH' (ᴣ)
        'ꜰ': 'F', // LATIN LETTER SMALL CAPITAL F
        'ɢ': 'G', // LATIN LETTER SMALL CAPITAL G
        'ʛ': 'G', // LATIN LETTER SMALL CAPITAL G WITH HOOK
        'ʜ': 'H', // LATIN LETTER SMALL CAPITAL H
        'ɪ': 'I', // LATIN LETTER SMALL CAPITAL I
        'ʁ': 'R', // LATIN LETTER SMALL CAPITAL INVERTED R
        'ᴊ': 'J', // LATIN LETTER SMALL CAPITAL J
        'ᴋ': 'K', // LATIN LETTER SMALL CAPITAL K
        'ʟ': 'L', // LATIN LETTER SMALL CAPITAL L
        'ᴌ': 'L', // LATIN LETTER SMALL CAPITAL L WITH STROKE
        'ᴍ': 'M', // LATIN LETTER SMALL CAPITAL M
        'ɴ': 'N', // LATIN LETTER SMALL CAPITAL N
        'ᴏ': 'O', // LATIN LETTER SMALL CAPITAL O
        'ɶ': 'OE', // LATIN LETTER SMALL CAPITAL OE
        'ᴐ': 'O', // LATIN LETTER SMALL CAPITAL OPEN O
        'ᴕ': 'OU', // LATIN LETTER SMALL CAPITAL OU
        'ᴘ': 'P', // LATIN LETTER SMALL CAPITAL P
        'ʀ': 'R', // LATIN LETTER SMALL CAPITAL R
        'ᴎ': 'N', // LATIN LETTER SMALL CAPITAL REVERSED N
        'ᴙ': 'R', // LATIN LETTER SMALL CAPITAL REVERSED R
// CANNOT FIND APPROXIMATION FOR 'LATIN LETTER SMALL CAPITAL RUM' (ꝶ)
        'ꜱ': 'S', // LATIN LETTER SMALL CAPITAL S
        'ᴛ': 'T', // LATIN LETTER SMALL CAPITAL T
        'ⱻ': 'E', // LATIN LETTER SMALL CAPITAL TURNED E
        'ᴚ': 'R', // LATIN LETTER SMALL CAPITAL TURNED R
        'ᴜ': 'U', // LATIN LETTER SMALL CAPITAL U
        'ᴠ': 'V', // LATIN LETTER SMALL CAPITAL V
        'ᴡ': 'W', // LATIN LETTER SMALL CAPITAL W
        'ʏ': 'Y', // LATIN LETTER SMALL CAPITAL Y
        'ᴢ': 'Z', // LATIN LETTER SMALL CAPITAL Z
// CANNOT FIND APPROXIMATION FOR 'LATIN LETTER STRETCHED C' (ʗ)
// CANNOT FIND APPROXIMATION FOR 'LATIN LETTER TWO WITH STROKE' (ƻ)
// CANNOT FIND APPROXIMATION FOR 'LATIN LETTER VOICED LARYNGEAL SPIRANT' (ᴤ)
// CANNOT FIND APPROXIMATION FOR 'LATIN LETTER WYNN' (ƿ)
// CANNOT FIND APPROXIMATION FOR 'LATIN LETTER YR' (Ʀ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL CAPITAL LETTER I WITH STROKE' (ᵻ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL CAPITAL LETTER U WITH STROKE' (ᵾ)
        'á': 'a', // LATIN SMALL LETTER A WITH ACUTE
        'ă': 'a', // LATIN SMALL LETTER A WITH BREVE
        'ắ': 'a', // LATIN SMALL LETTER A WITH BREVE AND ACUTE
        'ặ': 'a', // LATIN SMALL LETTER A WITH BREVE AND DOT BELOW
        'ằ': 'a', // LATIN SMALL LETTER A WITH BREVE AND GRAVE
        'ẳ': 'a', // LATIN SMALL LETTER A WITH BREVE AND HOOK ABOVE
        'ẵ': 'a', // LATIN SMALL LETTER A WITH BREVE AND TILDE
        'ǎ': 'a', // LATIN SMALL LETTER A WITH CARON
        'â': 'a', // LATIN SMALL LETTER A WITH CIRCUMFLEX
        'ấ': 'a', // LATIN SMALL LETTER A WITH CIRCUMFLEX AND ACUTE
        'ậ': 'a', // LATIN SMALL LETTER A WITH CIRCUMFLEX AND DOT BELOW
        'ầ': 'a', // LATIN SMALL LETTER A WITH CIRCUMFLEX AND GRAVE
        'ẩ': 'a', // LATIN SMALL LETTER A WITH CIRCUMFLEX AND HOOK ABOVE
        'ẫ': 'a', // LATIN SMALL LETTER A WITH CIRCUMFLEX AND TILDE
        'ä': 'a', // LATIN SMALL LETTER A WITH DIAERESIS
        'ǟ': 'a', // LATIN SMALL LETTER A WITH DIAERESIS AND MACRON
        'ȧ': 'a', // LATIN SMALL LETTER A WITH DOT ABOVE
        'ǡ': 'a', // LATIN SMALL LETTER A WITH DOT ABOVE AND MACRON
        'ạ': 'a', // LATIN SMALL LETTER A WITH DOT BELOW
        'ȁ': 'a', // LATIN SMALL LETTER A WITH DOUBLE GRAVE
        'à': 'a', // LATIN SMALL LETTER A WITH GRAVE
        'ả': 'a', // LATIN SMALL LETTER A WITH HOOK ABOVE
        'ȃ': 'a', // LATIN SMALL LETTER A WITH INVERTED BREVE
        'ā': 'a', // LATIN SMALL LETTER A WITH MACRON
        'ą': 'a', // LATIN SMALL LETTER A WITH OGONEK
        'ᶏ': 'a', // LATIN SMALL LETTER A WITH RETROFLEX HOOK
        'ẚ': 'a', // LATIN SMALL LETTER A WITH RIGHT HALF RING
        'å': 'a', // LATIN SMALL LETTER A WITH RING ABOVE
        'ǻ': 'a', // LATIN SMALL LETTER A WITH RING ABOVE AND ACUTE
        'ḁ': 'a', // LATIN SMALL LETTER A WITH RING BELOW
        'ⱥ': 'a', // LATIN SMALL LETTER A WITH STROKE
        'ã': 'a', // LATIN SMALL LETTER A WITH TILDE
        'ꜳ': 'aa', // LATIN SMALL LETTER AA
        'æ': 'ae', // LATIN SMALL LETTER AE
        'ǽ': 'ae', // LATIN SMALL LETTER AE WITH ACUTE
        'ǣ': 'ae', // LATIN SMALL LETTER AE WITH MACRON
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER ALPHA' (ɑ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER ALPHA WITH RETROFLEX HOOK' (ᶐ)
        'ꜵ': 'ao', // LATIN SMALL LETTER AO
        'ꜷ': 'au', // LATIN SMALL LETTER AU
        'ꜹ': 'av', // LATIN SMALL LETTER AV
        'ꜻ': 'av', // LATIN SMALL LETTER AV WITH HORIZONTAL BAR
        'ꜽ': 'ay', // LATIN SMALL LETTER AY
        'ḃ': 'b', // LATIN SMALL LETTER B WITH DOT ABOVE
        'ḅ': 'b', // LATIN SMALL LETTER B WITH DOT BELOW
        'ɓ': 'b', // LATIN SMALL LETTER B WITH HOOK
        'ḇ': 'b', // LATIN SMALL LETTER B WITH LINE BELOW
        'ᵬ': 'b', // LATIN SMALL LETTER B WITH MIDDLE TILDE
        'ᶀ': 'b', // LATIN SMALL LETTER B WITH PALATAL HOOK
        'ƀ': 'b', // LATIN SMALL LETTER B WITH STROKE
        'ƃ': 'b', // LATIN SMALL LETTER B WITH TOPBAR
        'ɵ': 'o', // LATIN SMALL LETTER BARRED O
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER BOTTOM HALF O' (ᴗ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER BROKEN L' (ꝇ)
        'ć': 'c', // LATIN SMALL LETTER C WITH ACUTE
        'č': 'c', // LATIN SMALL LETTER C WITH CARON
        'ç': 'c', // LATIN SMALL LETTER C WITH CEDILLA
        'ḉ': 'c', // LATIN SMALL LETTER C WITH CEDILLA AND ACUTE
        'ĉ': 'c', // LATIN SMALL LETTER C WITH CIRCUMFLEX
        'ɕ': 'c', // LATIN SMALL LETTER C WITH CURL
        'ċ': 'c', // LATIN SMALL LETTER C WITH DOT ABOVE
        'ƈ': 'c', // LATIN SMALL LETTER C WITH HOOK
        'ȼ': 'c', // LATIN SMALL LETTER C WITH STROKE
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER CLOSED OMEGA' (ɷ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER CLOSED OPEN E' (ʚ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER CLOSED REVERSED OPEN E' (ɞ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER CON' (ꝯ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER CUATRILLO' (ꜭ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER CUATRILLO WITH COMMA' (ꜯ)
        'ď': 'd', // LATIN SMALL LETTER D WITH CARON
        'ḑ': 'd', // LATIN SMALL LETTER D WITH CEDILLA
        'ḓ': 'd', // LATIN SMALL LETTER D WITH CIRCUMFLEX BELOW
        'ȡ': 'd', // LATIN SMALL LETTER D WITH CURL
        'ḋ': 'd', // LATIN SMALL LETTER D WITH DOT ABOVE
        'ḍ': 'd', // LATIN SMALL LETTER D WITH DOT BELOW
        'ɗ': 'd', // LATIN SMALL LETTER D WITH HOOK
        'ᶑ': 'd', // LATIN SMALL LETTER D WITH HOOK AND TAIL
        'ḏ': 'd', // LATIN SMALL LETTER D WITH LINE BELOW
        'ᵭ': 'd', // LATIN SMALL LETTER D WITH MIDDLE TILDE
        'ᶁ': 'd', // LATIN SMALL LETTER D WITH PALATAL HOOK
        'đ': 'd', // LATIN SMALL LETTER D WITH STROKE
        'ɖ': 'd', // LATIN SMALL LETTER D WITH TAIL
        'ƌ': 'd', // LATIN SMALL LETTER D WITH TOPBAR
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER DB DIGRAPH' (ȸ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER DELTA' (ẟ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER DEZH DIGRAPH' (ʤ)
        'ı': 'i', // LATIN SMALL LETTER DOTLESS I
        'ȷ': 'j', // LATIN SMALL LETTER DOTLESS J
        'ɟ': 'j', // LATIN SMALL LETTER DOTLESS J WITH STROKE
        'ʄ': 'j', // LATIN SMALL LETTER DOTLESS J WITH STROKE AND HOOK
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER DUM' (ꝱ)
        'ǳ': 'dz', // LATIN SMALL LETTER DZ
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER DZ DIGRAPH' (ʣ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER DZ DIGRAPH WITH CURL' (ʥ)
        'ǆ': 'dz', // LATIN SMALL LETTER DZ WITH CARON
        'é': 'e', // LATIN SMALL LETTER E WITH ACUTE
        'ĕ': 'e', // LATIN SMALL LETTER E WITH BREVE
        'ě': 'e', // LATIN SMALL LETTER E WITH CARON
        'ȩ': 'e', // LATIN SMALL LETTER E WITH CEDILLA
        'ḝ': 'e', // LATIN SMALL LETTER E WITH CEDILLA AND BREVE
        'ê': 'e', // LATIN SMALL LETTER E WITH CIRCUMFLEX
        'ế': 'e', // LATIN SMALL LETTER E WITH CIRCUMFLEX AND ACUTE
        'ệ': 'e', // LATIN SMALL LETTER E WITH CIRCUMFLEX AND DOT BELOW
        'ề': 'e', // LATIN SMALL LETTER E WITH CIRCUMFLEX AND GRAVE
        'ể': 'e', // LATIN SMALL LETTER E WITH CIRCUMFLEX AND HOOK ABOVE
        'ễ': 'e', // LATIN SMALL LETTER E WITH CIRCUMFLEX AND TILDE
        'ḙ': 'e', // LATIN SMALL LETTER E WITH CIRCUMFLEX BELOW
        'ë': 'e', // LATIN SMALL LETTER E WITH DIAERESIS
        'ė': 'e', // LATIN SMALL LETTER E WITH DOT ABOVE
        'ẹ': 'e', // LATIN SMALL LETTER E WITH DOT BELOW
        'ȅ': 'e', // LATIN SMALL LETTER E WITH DOUBLE GRAVE
        'è': 'e', // LATIN SMALL LETTER E WITH GRAVE
        'ẻ': 'e', // LATIN SMALL LETTER E WITH HOOK ABOVE
        'ȇ': 'e', // LATIN SMALL LETTER E WITH INVERTED BREVE
        'ē': 'e', // LATIN SMALL LETTER E WITH MACRON
        'ḗ': 'e', // LATIN SMALL LETTER E WITH MACRON AND ACUTE
        'ḕ': 'e', // LATIN SMALL LETTER E WITH MACRON AND GRAVE
        'ⱸ': 'e', // LATIN SMALL LETTER E WITH NOTCH
        'ę': 'e', // LATIN SMALL LETTER E WITH OGONEK
        'ᶒ': 'e', // LATIN SMALL LETTER E WITH RETROFLEX HOOK
        'ɇ': 'e', // LATIN SMALL LETTER E WITH STROKE
        'ẽ': 'e', // LATIN SMALL LETTER E WITH TILDE
        'ḛ': 'e', // LATIN SMALL LETTER E WITH TILDE BELOW
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER EGYPTOLOGICAL AIN' (ꜥ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER EGYPTOLOGICAL ALEF' (ꜣ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER ENG' (ŋ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER ESH' (ʃ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER ESH WITH CURL' (ʆ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER ESH WITH PALATAL HOOK' (ᶋ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER ESH WITH RETROFLEX HOOK' (ᶘ)
        'ꝫ': 'et', // LATIN SMALL LETTER ET
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER ETH' (ð)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER EZH' (ʒ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER EZH REVERSED' (ƹ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER EZH WITH CARON' (ǯ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER EZH WITH CURL' (ʓ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER EZH WITH RETROFLEX HOOK' (ᶚ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER EZH WITH TAIL' (ƺ)
        'ḟ': 'f', // LATIN SMALL LETTER F WITH DOT ABOVE
        'ƒ': 'f', // LATIN SMALL LETTER F WITH HOOK
        'ᵮ': 'f', // LATIN SMALL LETTER F WITH MIDDLE TILDE
        'ᶂ': 'f', // LATIN SMALL LETTER F WITH PALATAL HOOK
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER FENG DIGRAPH' (ʩ)
        'ǵ': 'g', // LATIN SMALL LETTER G WITH ACUTE
        'ğ': 'g', // LATIN SMALL LETTER G WITH BREVE
        'ǧ': 'g', // LATIN SMALL LETTER G WITH CARON
        'ģ': 'g', // LATIN SMALL LETTER G WITH CEDILLA
        'ĝ': 'g', // LATIN SMALL LETTER G WITH CIRCUMFLEX
        'ġ': 'g', // LATIN SMALL LETTER G WITH DOT ABOVE
        'ɠ': 'g', // LATIN SMALL LETTER G WITH HOOK
        'ḡ': 'g', // LATIN SMALL LETTER G WITH MACRON
        'ᶃ': 'g', // LATIN SMALL LETTER G WITH PALATAL HOOK
        'ǥ': 'g', // LATIN SMALL LETTER G WITH STROKE
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER GAMMA' (ɣ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER GLOTTAL STOP' (ɂ)
        'ḫ': 'h', // LATIN SMALL LETTER H WITH BREVE BELOW
        'ȟ': 'h', // LATIN SMALL LETTER H WITH CARON
        'ḩ': 'h', // LATIN SMALL LETTER H WITH CEDILLA
        'ĥ': 'h', // LATIN SMALL LETTER H WITH CIRCUMFLEX
        'ⱨ': 'h', // LATIN SMALL LETTER H WITH DESCENDER
        'ḧ': 'h', // LATIN SMALL LETTER H WITH DIAERESIS
        'ḣ': 'h', // LATIN SMALL LETTER H WITH DOT ABOVE
        'ḥ': 'h', // LATIN SMALL LETTER H WITH DOT BELOW
        'ɦ': 'h', // LATIN SMALL LETTER H WITH HOOK
        'ẖ': 'h', // LATIN SMALL LETTER H WITH LINE BELOW
        'ħ': 'h', // LATIN SMALL LETTER H WITH STROKE
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER HALF H' (ⱶ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER HENG' (ꜧ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER HENG WITH HOOK' (ɧ)
        'ƕ': 'hv', // LATIN SMALL LETTER HV
        'í': 'i', // LATIN SMALL LETTER I WITH ACUTE
        'ĭ': 'i', // LATIN SMALL LETTER I WITH BREVE
        'ǐ': 'i', // LATIN SMALL LETTER I WITH CARON
        'î': 'i', // LATIN SMALL LETTER I WITH CIRCUMFLEX
        'ï': 'i', // LATIN SMALL LETTER I WITH DIAERESIS
        'ḯ': 'i', // LATIN SMALL LETTER I WITH DIAERESIS AND ACUTE
        'ị': 'i', // LATIN SMALL LETTER I WITH DOT BELOW
        'ȉ': 'i', // LATIN SMALL LETTER I WITH DOUBLE GRAVE
        'ì': 'i', // LATIN SMALL LETTER I WITH GRAVE
        'ỉ': 'i', // LATIN SMALL LETTER I WITH HOOK ABOVE
        'ȋ': 'i', // LATIN SMALL LETTER I WITH INVERTED BREVE
        'ī': 'i', // LATIN SMALL LETTER I WITH MACRON
        'į': 'i', // LATIN SMALL LETTER I WITH OGONEK
        'ᶖ': 'i', // LATIN SMALL LETTER I WITH RETROFLEX HOOK
        'ɨ': 'i', // LATIN SMALL LETTER I WITH STROKE
        'ĩ': 'i', // LATIN SMALL LETTER I WITH TILDE
        'ḭ': 'i', // LATIN SMALL LETTER I WITH TILDE BELOW
        'ꝺ': 'd', // LATIN SMALL LETTER INSULAR D
        'ꝼ': 'f', // LATIN SMALL LETTER INSULAR F
        'ᵹ': 'g', // LATIN SMALL LETTER INSULAR G
        'ꞃ': 'r', // LATIN SMALL LETTER INSULAR R
        'ꞅ': 's', // LATIN SMALL LETTER INSULAR S
        'ꞇ': 't', // LATIN SMALL LETTER INSULAR T
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER IOTA' (ɩ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER IOTA WITH STROKE' (ᵼ)
        'ꝭ': 'is', // LATIN SMALL LETTER IS
        'ǰ': 'j', // LATIN SMALL LETTER J WITH CARON
        'ĵ': 'j', // LATIN SMALL LETTER J WITH CIRCUMFLEX
        'ʝ': 'j', // LATIN SMALL LETTER J WITH CROSSED-TAIL
        'ɉ': 'j', // LATIN SMALL LETTER J WITH STROKE
        'ḱ': 'k', // LATIN SMALL LETTER K WITH ACUTE
        'ǩ': 'k', // LATIN SMALL LETTER K WITH CARON
        'ķ': 'k', // LATIN SMALL LETTER K WITH CEDILLA
        'ⱪ': 'k', // LATIN SMALL LETTER K WITH DESCENDER
        'ꝃ': 'k', // LATIN SMALL LETTER K WITH DIAGONAL STROKE
        'ḳ': 'k', // LATIN SMALL LETTER K WITH DOT BELOW
        'ƙ': 'k', // LATIN SMALL LETTER K WITH HOOK
        'ḵ': 'k', // LATIN SMALL LETTER K WITH LINE BELOW
        'ᶄ': 'k', // LATIN SMALL LETTER K WITH PALATAL HOOK
        'ꝁ': 'k', // LATIN SMALL LETTER K WITH STROKE
        'ꝅ': 'k', // LATIN SMALL LETTER K WITH STROKE AND DIAGONAL STROKE
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER KRA' (ĸ)
        'ĺ': 'l', // LATIN SMALL LETTER L WITH ACUTE
        'ƚ': 'l', // LATIN SMALL LETTER L WITH BAR
        'ɬ': 'l', // LATIN SMALL LETTER L WITH BELT
        'ľ': 'l', // LATIN SMALL LETTER L WITH CARON
        'ļ': 'l', // LATIN SMALL LETTER L WITH CEDILLA
        'ḽ': 'l', // LATIN SMALL LETTER L WITH CIRCUMFLEX BELOW
        'ȴ': 'l', // LATIN SMALL LETTER L WITH CURL
        'ḷ': 'l', // LATIN SMALL LETTER L WITH DOT BELOW
        'ḹ': 'l', // LATIN SMALL LETTER L WITH DOT BELOW AND MACRON
        'ⱡ': 'l', // LATIN SMALL LETTER L WITH DOUBLE BAR
        'ꝉ': 'l', // LATIN SMALL LETTER L WITH HIGH STROKE
        'ḻ': 'l', // LATIN SMALL LETTER L WITH LINE BELOW
        'ŀ': 'l', // LATIN SMALL LETTER L WITH MIDDLE DOT
        'ɫ': 'l', // LATIN SMALL LETTER L WITH MIDDLE TILDE
        'ᶅ': 'l', // LATIN SMALL LETTER L WITH PALATAL HOOK
        'ɭ': 'l', // LATIN SMALL LETTER L WITH RETROFLEX HOOK
        'ł': 'l', // LATIN SMALL LETTER L WITH STROKE
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER LAMBDA WITH STROKE' (ƛ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER LEZH' (ɮ)
        'ǉ': 'lj', // LATIN SMALL LETTER LJ
        'ſ': 's', // LATIN SMALL LETTER LONG S
        'ẜ': 's', // LATIN SMALL LETTER LONG S WITH DIAGONAL STROKE
        'ẛ': 's', // LATIN SMALL LETTER LONG S WITH DOT ABOVE
        'ẝ': 's', // LATIN SMALL LETTER LONG S WITH HIGH STROKE
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER LS DIGRAPH' (ʪ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER LUM' (ꝲ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER LZ DIGRAPH' (ʫ)
        'ḿ': 'm', // LATIN SMALL LETTER M WITH ACUTE
        'ṁ': 'm', // LATIN SMALL LETTER M WITH DOT ABOVE
        'ṃ': 'm', // LATIN SMALL LETTER M WITH DOT BELOW
        'ɱ': 'm', // LATIN SMALL LETTER M WITH HOOK
        'ᵯ': 'm', // LATIN SMALL LETTER M WITH MIDDLE TILDE
        'ᶆ': 'm', // LATIN SMALL LETTER M WITH PALATAL HOOK
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER MIDDLE-WELSH LL' (ỻ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER MIDDLE-WELSH V' (ỽ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER MUM' (ꝳ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER N PRECEDED BY APOSTROPHE' (ŉ)
        'ń': 'n', // LATIN SMALL LETTER N WITH ACUTE
        'ň': 'n', // LATIN SMALL LETTER N WITH CARON
        'ņ': 'n', // LATIN SMALL LETTER N WITH CEDILLA
        'ṋ': 'n', // LATIN SMALL LETTER N WITH CIRCUMFLEX BELOW
        'ȵ': 'n', // LATIN SMALL LETTER N WITH CURL
        'ṅ': 'n', // LATIN SMALL LETTER N WITH DOT ABOVE
        'ṇ': 'n', // LATIN SMALL LETTER N WITH DOT BELOW
        'ǹ': 'n', // LATIN SMALL LETTER N WITH GRAVE
        'ɲ': 'n', // LATIN SMALL LETTER N WITH LEFT HOOK
        'ṉ': 'n', // LATIN SMALL LETTER N WITH LINE BELOW
        'ƞ': 'n', // LATIN SMALL LETTER N WITH LONG RIGHT LEG
        'ᵰ': 'n', // LATIN SMALL LETTER N WITH MIDDLE TILDE
        'ᶇ': 'n', // LATIN SMALL LETTER N WITH PALATAL HOOK
        'ɳ': 'n', // LATIN SMALL LETTER N WITH RETROFLEX HOOK
        'ñ': 'n', // LATIN SMALL LETTER N WITH TILDE
        'ǌ': 'nj', // LATIN SMALL LETTER NJ
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER NUM' (ꝴ)
        'ó': 'o', // LATIN SMALL LETTER O WITH ACUTE
        'ŏ': 'o', // LATIN SMALL LETTER O WITH BREVE
        'ǒ': 'o', // LATIN SMALL LETTER O WITH CARON
        'ô': 'o', // LATIN SMALL LETTER O WITH CIRCUMFLEX
        'ố': 'o', // LATIN SMALL LETTER O WITH CIRCUMFLEX AND ACUTE
        'ộ': 'o', // LATIN SMALL LETTER O WITH CIRCUMFLEX AND DOT BELOW
        'ồ': 'o', // LATIN SMALL LETTER O WITH CIRCUMFLEX AND GRAVE
        'ổ': 'o', // LATIN SMALL LETTER O WITH CIRCUMFLEX AND HOOK ABOVE
        'ỗ': 'o', // LATIN SMALL LETTER O WITH CIRCUMFLEX AND TILDE
        'ö': 'o', // LATIN SMALL LETTER O WITH DIAERESIS
        'ȫ': 'o', // LATIN SMALL LETTER O WITH DIAERESIS AND MACRON
        'ȯ': 'o', // LATIN SMALL LETTER O WITH DOT ABOVE
        'ȱ': 'o', // LATIN SMALL LETTER O WITH DOT ABOVE AND MACRON
        'ọ': 'o', // LATIN SMALL LETTER O WITH DOT BELOW
        'ő': 'o', // LATIN SMALL LETTER O WITH DOUBLE ACUTE
        'ȍ': 'o', // LATIN SMALL LETTER O WITH DOUBLE GRAVE
        'ò': 'o', // LATIN SMALL LETTER O WITH GRAVE
        'ỏ': 'o', // LATIN SMALL LETTER O WITH HOOK ABOVE
        'ơ': 'o', // LATIN SMALL LETTER O WITH HORN
        'ớ': 'o', // LATIN SMALL LETTER O WITH HORN AND ACUTE
        'ợ': 'o', // LATIN SMALL LETTER O WITH HORN AND DOT BELOW
        'ờ': 'o', // LATIN SMALL LETTER O WITH HORN AND GRAVE
        'ở': 'o', // LATIN SMALL LETTER O WITH HORN AND HOOK ABOVE
        'ỡ': 'o', // LATIN SMALL LETTER O WITH HORN AND TILDE
        'ȏ': 'o', // LATIN SMALL LETTER O WITH INVERTED BREVE
        'ꝋ': 'o', // LATIN SMALL LETTER O WITH LONG STROKE OVERLAY
        'ꝍ': 'o', // LATIN SMALL LETTER O WITH LOOP
        'ⱺ': 'o', // LATIN SMALL LETTER O WITH LOW RING INSIDE
        'ō': 'o', // LATIN SMALL LETTER O WITH MACRON
        'ṓ': 'o', // LATIN SMALL LETTER O WITH MACRON AND ACUTE
        'ṑ': 'o', // LATIN SMALL LETTER O WITH MACRON AND GRAVE
        'ǫ': 'o', // LATIN SMALL LETTER O WITH OGONEK
        'ǭ': 'o', // LATIN SMALL LETTER O WITH OGONEK AND MACRON
        'ø': 'o', // LATIN SMALL LETTER O WITH STROKE
        'ǿ': 'o', // LATIN SMALL LETTER O WITH STROKE AND ACUTE
        'õ': 'o', // LATIN SMALL LETTER O WITH TILDE
        'ṍ': 'o', // LATIN SMALL LETTER O WITH TILDE AND ACUTE
        'ṏ': 'o', // LATIN SMALL LETTER O WITH TILDE AND DIAERESIS
        'ȭ': 'o', // LATIN SMALL LETTER O WITH TILDE AND MACRON
        'ƣ': 'oi', // LATIN SMALL LETTER OI
        'ꝏ': 'oo', // LATIN SMALL LETTER OO
        'ɛ': 'e', // LATIN SMALL LETTER OPEN E
        'ᶓ': 'e', // LATIN SMALL LETTER OPEN E WITH RETROFLEX HOOK
        'ɔ': 'o', // LATIN SMALL LETTER OPEN O
        'ᶗ': 'o', // LATIN SMALL LETTER OPEN O WITH RETROFLEX HOOK
        'ȣ': 'ou', // LATIN SMALL LETTER OU
        'ṕ': 'p', // LATIN SMALL LETTER P WITH ACUTE
        'ṗ': 'p', // LATIN SMALL LETTER P WITH DOT ABOVE
        'ꝓ': 'p', // LATIN SMALL LETTER P WITH FLOURISH
        'ƥ': 'p', // LATIN SMALL LETTER P WITH HOOK
        'ᵱ': 'p', // LATIN SMALL LETTER P WITH MIDDLE TILDE
        'ᶈ': 'p', // LATIN SMALL LETTER P WITH PALATAL HOOK
        'ꝕ': 'p', // LATIN SMALL LETTER P WITH SQUIRREL TAIL
        'ᵽ': 'p', // LATIN SMALL LETTER P WITH STROKE
        'ꝑ': 'p', // LATIN SMALL LETTER P WITH STROKE THROUGH DESCENDER
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER PHI' (ɸ)
        'ꝙ': 'q', // LATIN SMALL LETTER Q WITH DIAGONAL STROKE
        'ʠ': 'q', // LATIN SMALL LETTER Q WITH HOOK
        'ɋ': 'q', // LATIN SMALL LETTER Q WITH HOOK TAIL
        'ꝗ': 'q', // LATIN SMALL LETTER Q WITH STROKE THROUGH DESCENDER
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER QP DIGRAPH' (ȹ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER R ROTUNDA' (ꝛ)
        'ŕ': 'r', // LATIN SMALL LETTER R WITH ACUTE
        'ř': 'r', // LATIN SMALL LETTER R WITH CARON
        'ŗ': 'r', // LATIN SMALL LETTER R WITH CEDILLA
        'ṙ': 'r', // LATIN SMALL LETTER R WITH DOT ABOVE
        'ṛ': 'r', // LATIN SMALL LETTER R WITH DOT BELOW
        'ṝ': 'r', // LATIN SMALL LETTER R WITH DOT BELOW AND MACRON
        'ȑ': 'r', // LATIN SMALL LETTER R WITH DOUBLE GRAVE
        'ɾ': 'r', // LATIN SMALL LETTER R WITH FISHHOOK
        'ᵳ': 'r', // LATIN SMALL LETTER R WITH FISHHOOK AND MIDDLE TILDE
        'ȓ': 'r', // LATIN SMALL LETTER R WITH INVERTED BREVE
        'ṟ': 'r', // LATIN SMALL LETTER R WITH LINE BELOW
        'ɼ': 'r', // LATIN SMALL LETTER R WITH LONG LEG
        'ᵲ': 'r', // LATIN SMALL LETTER R WITH MIDDLE TILDE
        'ᶉ': 'r', // LATIN SMALL LETTER R WITH PALATAL HOOK
        'ɍ': 'r', // LATIN SMALL LETTER R WITH STROKE
        'ɽ': 'r', // LATIN SMALL LETTER R WITH TAIL
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER RAMS HORN' (ɤ)
        'ↄ': 'c', // LATIN SMALL LETTER REVERSED C
        'ꜿ': 'c', // LATIN SMALL LETTER REVERSED C WITH DOT
        'ɘ': 'e', // LATIN SMALL LETTER REVERSED E
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER REVERSED OPEN E' (ɜ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER REVERSED OPEN E WITH HOOK' (ɝ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER REVERSED OPEN E WITH RETROFLEX HOOK' (ᶔ)
        'ɿ': 'r', // LATIN SMALL LETTER REVERSED R WITH FISHHOOK
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER RUM' (ꝵ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER RUM ROTUNDA' (ꝝ)
        'ś': 's', // LATIN SMALL LETTER S WITH ACUTE
        'ṥ': 's', // LATIN SMALL LETTER S WITH ACUTE AND DOT ABOVE
        'š': 's', // LATIN SMALL LETTER S WITH CARON
        'ṧ': 's', // LATIN SMALL LETTER S WITH CARON AND DOT ABOVE
        'ş': 's', // LATIN SMALL LETTER S WITH CEDILLA
        'ŝ': 's', // LATIN SMALL LETTER S WITH CIRCUMFLEX
        'ș': 's', // LATIN SMALL LETTER S WITH COMMA BELOW
        'ṡ': 's', // LATIN SMALL LETTER S WITH DOT ABOVE
        'ṣ': 's', // LATIN SMALL LETTER S WITH DOT BELOW
        'ṩ': 's', // LATIN SMALL LETTER S WITH DOT BELOW AND DOT ABOVE
        'ʂ': 's', // LATIN SMALL LETTER S WITH HOOK
        'ᵴ': 's', // LATIN SMALL LETTER S WITH MIDDLE TILDE
        'ᶊ': 's', // LATIN SMALL LETTER S WITH PALATAL HOOK
        'ȿ': 's', // LATIN SMALL LETTER S WITH SWASH TAIL
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER SALTILLO' (ꞌ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER SCHWA' (ə)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER SCHWA WITH HOOK' (ɚ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER SCHWA WITH RETROFLEX HOOK' (ᶕ)
        'ɡ': 'g', // LATIN SMALL LETTER SCRIPT G
        'ß': 'ss', // LATIN SMALL LETTER SHARP S
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER SIDEWAYS DIAERESIZED U' (ᴞ)
        'ᴑ': 'o', // LATIN SMALL LETTER SIDEWAYS O
        'ᴓ': 'o', // LATIN SMALL LETTER SIDEWAYS O WITH STROKE
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER SIDEWAYS OPEN O' (ᴒ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER SIDEWAYS TURNED M' (ᴟ)
        'ᴝ': 'u', // LATIN SMALL LETTER SIDEWAYS U
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER SQUAT REVERSED ESH' (ʅ)
        'ť': 't', // LATIN SMALL LETTER T WITH CARON
        'ţ': 't', // LATIN SMALL LETTER T WITH CEDILLA
        'ṱ': 't', // LATIN SMALL LETTER T WITH CIRCUMFLEX BELOW
        'ț': 't', // LATIN SMALL LETTER T WITH COMMA BELOW
        'ȶ': 't', // LATIN SMALL LETTER T WITH CURL
        'ẗ': 't', // LATIN SMALL LETTER T WITH DIAERESIS
        'ⱦ': 't', // LATIN SMALL LETTER T WITH DIAGONAL STROKE
        'ṫ': 't', // LATIN SMALL LETTER T WITH DOT ABOVE
        'ṭ': 't', // LATIN SMALL LETTER T WITH DOT BELOW
        'ƭ': 't', // LATIN SMALL LETTER T WITH HOOK
        'ṯ': 't', // LATIN SMALL LETTER T WITH LINE BELOW
        'ᵵ': 't', // LATIN SMALL LETTER T WITH MIDDLE TILDE
        'ƫ': 't', // LATIN SMALL LETTER T WITH PALATAL HOOK
        'ʈ': 't', // LATIN SMALL LETTER T WITH RETROFLEX HOOK
        'ŧ': 't', // LATIN SMALL LETTER T WITH STROKE
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER TAILLESS PHI' (ⱷ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER TC DIGRAPH WITH CURL' (ʨ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER TESH DIGRAPH' (ʧ)
        'ᵺ': 'th', // LATIN SMALL LETTER TH WITH STRIKETHROUGH
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER THORN' (þ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER THORN WITH STROKE' (ꝥ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER THORN WITH STROKE THROUGH DESCENDER' (ꝧ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER TONE FIVE' (ƽ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER TONE SIX' (ƅ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER TONE TWO' (ƨ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER TOP HALF O' (ᴖ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER TRESILLO' (ꜫ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER TS DIGRAPH' (ʦ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER TUM' (ꝷ)
        'ɐ': 'a', // LATIN SMALL LETTER TURNED A
        'ᴂ': 'ae', // LATIN SMALL LETTER TURNED AE
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER TURNED ALPHA' (ɒ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER TURNED DELTA' (ƍ)
        'ǝ': 'e', // LATIN SMALL LETTER TURNED E
        'ᵷ': 'g', // LATIN SMALL LETTER TURNED G
        'ɥ': 'h', // LATIN SMALL LETTER TURNED H
        'ʮ': 'h', // LATIN SMALL LETTER TURNED H WITH FISHHOOK
        'ʯ': 'h', // LATIN SMALL LETTER TURNED H WITH FISHHOOK AND TAIL
        'ᴉ': 'i', // LATIN SMALL LETTER TURNED I
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER TURNED INSULAR G' (ꝿ)
        'ʞ': 'k', // LATIN SMALL LETTER TURNED K
        'ꞁ': 'l', // LATIN SMALL LETTER TURNED L
        'ɯ': 'm', // LATIN SMALL LETTER TURNED M
        'ɰ': 'm', // LATIN SMALL LETTER TURNED M WITH LONG LEG
        'ᴔ': 'oe', // LATIN SMALL LETTER TURNED OE
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER TURNED OPEN E' (ᴈ)
        'ɹ': 'r', // LATIN SMALL LETTER TURNED R
        'ɻ': 'r', // LATIN SMALL LETTER TURNED R WITH HOOK
        'ɺ': 'r', // LATIN SMALL LETTER TURNED R WITH LONG LEG
        'ⱹ': 'r', // LATIN SMALL LETTER TURNED R WITH TAIL
        'ʇ': 't', // LATIN SMALL LETTER TURNED T
        'ʌ': 'v', // LATIN SMALL LETTER TURNED V
        'ʍ': 'w', // LATIN SMALL LETTER TURNED W
        'ʎ': 'y', // LATIN SMALL LETTER TURNED Y
        'ꜩ': 'tz', // LATIN SMALL LETTER TZ
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER U BAR' (ʉ)
        'ú': 'u', // LATIN SMALL LETTER U WITH ACUTE
        'ŭ': 'u', // LATIN SMALL LETTER U WITH BREVE
        'ǔ': 'u', // LATIN SMALL LETTER U WITH CARON
        'û': 'u', // LATIN SMALL LETTER U WITH CIRCUMFLEX
        'ṷ': 'u', // LATIN SMALL LETTER U WITH CIRCUMFLEX BELOW
        'ü': 'u', // LATIN SMALL LETTER U WITH DIAERESIS
        'ǘ': 'u', // LATIN SMALL LETTER U WITH DIAERESIS AND ACUTE
        'ǚ': 'u', // LATIN SMALL LETTER U WITH DIAERESIS AND CARON
        'ǜ': 'u', // LATIN SMALL LETTER U WITH DIAERESIS AND GRAVE
        'ǖ': 'u', // LATIN SMALL LETTER U WITH DIAERESIS AND MACRON
        'ṳ': 'u', // LATIN SMALL LETTER U WITH DIAERESIS BELOW
        'ụ': 'u', // LATIN SMALL LETTER U WITH DOT BELOW
        'ű': 'u', // LATIN SMALL LETTER U WITH DOUBLE ACUTE
        'ȕ': 'u', // LATIN SMALL LETTER U WITH DOUBLE GRAVE
        'ù': 'u', // LATIN SMALL LETTER U WITH GRAVE
        'ủ': 'u', // LATIN SMALL LETTER U WITH HOOK ABOVE
        'ư': 'u', // LATIN SMALL LETTER U WITH HORN
        'ứ': 'u', // LATIN SMALL LETTER U WITH HORN AND ACUTE
        'ự': 'u', // LATIN SMALL LETTER U WITH HORN AND DOT BELOW
        'ừ': 'u', // LATIN SMALL LETTER U WITH HORN AND GRAVE
        'ử': 'u', // LATIN SMALL LETTER U WITH HORN AND HOOK ABOVE
        'ữ': 'u', // LATIN SMALL LETTER U WITH HORN AND TILDE
        'ȗ': 'u', // LATIN SMALL LETTER U WITH INVERTED BREVE
        'ū': 'u', // LATIN SMALL LETTER U WITH MACRON
        'ṻ': 'u', // LATIN SMALL LETTER U WITH MACRON AND DIAERESIS
        'ų': 'u', // LATIN SMALL LETTER U WITH OGONEK
        'ᶙ': 'u', // LATIN SMALL LETTER U WITH RETROFLEX HOOK
        'ů': 'u', // LATIN SMALL LETTER U WITH RING ABOVE
        'ũ': 'u', // LATIN SMALL LETTER U WITH TILDE
        'ṹ': 'u', // LATIN SMALL LETTER U WITH TILDE AND ACUTE
        'ṵ': 'u', // LATIN SMALL LETTER U WITH TILDE BELOW
        'ᵫ': 'ue', // LATIN SMALL LETTER UE
        'ꝸ': 'um', // LATIN SMALL LETTER UM
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER UPSILON' (ʊ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER UPSILON WITH STROKE' (ᵿ)
        'ⱴ': 'v', // LATIN SMALL LETTER V WITH CURL
        'ꝟ': 'v', // LATIN SMALL LETTER V WITH DIAGONAL STROKE
        'ṿ': 'v', // LATIN SMALL LETTER V WITH DOT BELOW
        'ʋ': 'v', // LATIN SMALL LETTER V WITH HOOK
        'ᶌ': 'v', // LATIN SMALL LETTER V WITH PALATAL HOOK
        'ⱱ': 'v', // LATIN SMALL LETTER V WITH RIGHT HOOK
        'ṽ': 'v', // LATIN SMALL LETTER V WITH TILDE
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER VEND' (ꝩ)
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER VISIGOTHIC Z' (ꝣ)
        'ꝡ': 'vy', // LATIN SMALL LETTER VY
        'ẃ': 'w', // LATIN SMALL LETTER W WITH ACUTE
        'ŵ': 'w', // LATIN SMALL LETTER W WITH CIRCUMFLEX
        'ẅ': 'w', // LATIN SMALL LETTER W WITH DIAERESIS
        'ẇ': 'w', // LATIN SMALL LETTER W WITH DOT ABOVE
        'ẉ': 'w', // LATIN SMALL LETTER W WITH DOT BELOW
        'ẁ': 'w', // LATIN SMALL LETTER W WITH GRAVE
        'ⱳ': 'w', // LATIN SMALL LETTER W WITH HOOK
        'ẘ': 'w', // LATIN SMALL LETTER W WITH RING ABOVE
        'ẍ': 'x', // LATIN SMALL LETTER X WITH DIAERESIS
        'ẋ': 'x', // LATIN SMALL LETTER X WITH DOT ABOVE
        'ᶍ': 'x', // LATIN SMALL LETTER X WITH PALATAL HOOK
        'ý': 'y', // LATIN SMALL LETTER Y WITH ACUTE
        'ŷ': 'y', // LATIN SMALL LETTER Y WITH CIRCUMFLEX
        'ÿ': 'y', // LATIN SMALL LETTER Y WITH DIAERESIS
        'ẏ': 'y', // LATIN SMALL LETTER Y WITH DOT ABOVE
        'ỵ': 'y', // LATIN SMALL LETTER Y WITH DOT BELOW
        'ỳ': 'y', // LATIN SMALL LETTER Y WITH GRAVE
        'ƴ': 'y', // LATIN SMALL LETTER Y WITH HOOK
        'ỷ': 'y', // LATIN SMALL LETTER Y WITH HOOK ABOVE
        'ỿ': 'y', // LATIN SMALL LETTER Y WITH LOOP
        'ȳ': 'y', // LATIN SMALL LETTER Y WITH MACRON
        'ẙ': 'y', // LATIN SMALL LETTER Y WITH RING ABOVE
        'ɏ': 'y', // LATIN SMALL LETTER Y WITH STROKE
        'ỹ': 'y', // LATIN SMALL LETTER Y WITH TILDE
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LETTER YOGH' (ȝ)
        'ź': 'z', // LATIN SMALL LETTER Z WITH ACUTE
        'ž': 'z', // LATIN SMALL LETTER Z WITH CARON
        'ẑ': 'z', // LATIN SMALL LETTER Z WITH CIRCUMFLEX
        'ʑ': 'z', // LATIN SMALL LETTER Z WITH CURL
        'ⱬ': 'z', // LATIN SMALL LETTER Z WITH DESCENDER
        'ż': 'z', // LATIN SMALL LETTER Z WITH DOT ABOVE
        'ẓ': 'z', // LATIN SMALL LETTER Z WITH DOT BELOW
        'ȥ': 'z', // LATIN SMALL LETTER Z WITH HOOK
        'ẕ': 'z', // LATIN SMALL LETTER Z WITH LINE BELOW
        'ᵶ': 'z', // LATIN SMALL LETTER Z WITH MIDDLE TILDE
        'ᶎ': 'z', // LATIN SMALL LETTER Z WITH PALATAL HOOK
        'ʐ': 'z', // LATIN SMALL LETTER Z WITH RETROFLEX HOOK
        'ƶ': 'z', // LATIN SMALL LETTER Z WITH STROKE
        'ɀ': 'z', // LATIN SMALL LETTER Z WITH SWASH TAIL
        'ﬀ': 'ff', // LATIN SMALL LIGATURE FF
        'ﬃ': 'ffi', // LATIN SMALL LIGATURE FFI
        'ﬄ': 'ffl', // LATIN SMALL LIGATURE FFL
        'ﬁ': 'fi', // LATIN SMALL LIGATURE FI
        'ﬂ': 'fl', // LATIN SMALL LIGATURE FL
        'ĳ': 'ij', // LATIN SMALL LIGATURE IJ
// CANNOT FIND APPROXIMATION FOR 'LATIN SMALL LIGATURE LONG S T' (ﬅ)
        'œ': 'oe', // LATIN SMALL LIGATURE OE
        'ﬆ': 'st', // LATIN SMALL LIGATURE ST
        'ₐ': 'a', // LATIN SUBSCRIPT SMALL LETTER A
        'ₑ': 'e', // LATIN SUBSCRIPT SMALL LETTER E
        'ᵢ': 'i', // LATIN SUBSCRIPT SMALL LETTER I
        'ⱼ': 'j', // LATIN SUBSCRIPT SMALL LETTER J
        'ₒ': 'o', // LATIN SUBSCRIPT SMALL LETTER O
        'ᵣ': 'r', // LATIN SUBSCRIPT SMALL LETTER R
// CANNOT FIND APPROXIMATION FOR 'LATIN SUBSCRIPT SMALL LETTER SCHWA' (ₔ)
        'ᵤ': 'u', // LATIN SUBSCRIPT SMALL LETTER U
        'ᵥ': 'v', // LATIN SUBSCRIPT SMALL LETTER V
        'ₓ': 'x' // LATIN SUBSCRIPT SMALL LETTER X
    };

    String.prototype.latinise = function () {
        return this.replace(/[^A-Za-z0-9\[\] ]/g, function (x) {
            return Latinise.latin_map[x] || x;
        });
    };

});

