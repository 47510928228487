define('prizeoapplication',["jquery", "prizeoutils", "jqcountdown", "jqtimeago", "bootstrapmodal", "jqchosen", "jqcheckbox", "sly", "jqplaceholders", "mail_validator", "jqeasing", "prizeomagnify", "prizeotwitter", "selectize"], function ($) {
    "use strict";

    $(function () {

        $(".js-selectize").selectize();

        if (window.outerWidth < 641) {
            var viewPortTag = document.getElementById('viewport');
            viewPortTag.content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0";
        }

        // IE 7 fix HR
        $('html.ie7').find('hr').before('<div class="hr"></div>').end();

        // IE 7 before  & after
        $('html.ie7').find('.ico-before, .ico-after').prepend('<span class="before"></span>').append('<span class="after"></span>');

        // Fix placeholders
        $('input[placeholder], textarea[placeholder]').placeholder();

        // Remove iOS click event delay
        $.fn.skFastClick = function (handler, onlyTouch) {
            var getTouches = function (e) {
                var originalEvent = e.originalEvent;

                if (originalEvent) {
                    if (originalEvent.touches && originalEvent.touches.length) {
                        return originalEvent.touches;
                    } else if (originalEvent.changedTouches && originalEvent.changedTouches.length) {
                        return originalEvent.changedTouches;
                    }
                }
                return false;
            };

            return this.each(function () {
                var startEvent = {};
                var endEvent = {};
                var touchEnd = false;
                var touchReset = false;

                $(this)
                    .bind('touchstart', function (e) {
                        var touches = getTouches(e);
                        var data = touches ? touches[0] : e;
                        startEvent.time = endEvent.time = e.timestamp;
                        startEvent.coords = endEvent.coords = [ data.clientX, data.clientY ];

                        touchEnd = true;
                        touchReset = false;
                    })
                    .bind('touchmove', function (e) {
                        var touches = getTouches(e);
                        var data = touches ? touches[0] : e;
                        endEvent.time = e.timestamp;
                        endEvent.coords = [ data.clientX, data.clientY ];

                        if (Math.abs(endEvent.coords[1] - startEvent.coords[1]) < 30) {
                            touchReset = true;
                        }
                    })
                    .bind('touchend click', function (e) {
                        if (e.type == 'touchend' && !touchReset || !touchEnd && !onlyTouch) {
                            handler.call(this, e);
                            e.isDefaultPrevented() && e.preventDefault();
                        }
                        if (e.type == 'click') {
                            touchEnd = false;
                        }
                    });
            });
        };

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
            $('html').addClass('mobile');
        } else {
            $('html').addClass('desktop');
        }

        $(function () {
            // Copy-to-clipboard
            $(".js-copy-to-clipboard").click(function(e) {
                var input = $(this).parent().find('input');
                input.select();
                document.execCommand("copy");

                $(this).addClass('copied');
                $(this).html('Copied!');

                //input.blur();
            });

            $('.email-check-spelling').mail_validator({
                in_progress: null, // called when request is made to validator
                success: function (emailValidation) {

                    var error, className, container = $('.email-validation-result');

                    // reset container
                    container.hide().html('').removeClass('error').removeClass('info');

                    // invalid email address
                    if (!emailValidation.valid) {

                        error = polyglot.t('validation.enterValidEmail.js');

                        if (emailValidation.suggestion) {
                            error += '<br>' + polyglot.t('validation.didYouMean.js') + ' <a class="suggested-email">' + emailValidation.suggestion + '</a>?';
                        }

                        // email spelling suggestion
                    } else if (emailValidation.suggestion) {

                        error = polyglot.t('validation.didYouMean.js') + ' <a class="suggested-email">' + emailValidation.suggestion + '</a>?';
                    }

                    // Display error message
                    if (error) {

                        className = emailValidation.valid ? 'info' : 'error';

                        container.addClass(className);
                        container.html(error);
                        container.show();

                        if (className == 'error') {
                            $('.email-check-spelling').addClass('error');
                        }

                        $('.email-validation-result .suggested-email').off().on('click', function () {
                            // hide container
                            container.hide();
                            // remove error class
                            $('.email-check-spelling').removeClass('error');
                            // set suggested email address
                            $('.email-check-spelling').val($(this).text());
                            // revalidate
                            $('.email-check-spelling').blur();
                        });
                    }
                }
            });

            $('.email-check-spelling').on('keyup', function () {
                // remove error class when updating email address
                $(this).removeClass('error');
                // remove error container
                $('.email-validation-result').hide().html('').removeClass('error').removeClass('info')
            });

        });

        // Scroll to target, fix header overlay
        $(document).on('click', 'a[href^=#]:not(.thickbox)', function (e) {
            var hash = $(this).prop('hash').slice(1);

            if (hash && !$(this).closest('.tab-list').length) {
                var target = $('#' + hash);

                if (target.length) {
                    $('body,html').animate({scrollTop: target.offset().top - 100}, 500);
                    e.preventDefault();
                }
            }
        });

        // Set video players
        $(document)
            .on('contentload', function (e) {

                // Checkbox & radio
                $('input:checkbox:not(.react)', e.target).checkbox({
                    empty: '/assets/images/bg/spacer.png',
                    cls: 'jquery-checkbox'
                });
                $('input:radio', e.target).checkbox({
                    empty: '/assets/images/bg/spacer.png',
                    cls: 'jquery-radio'
                });

                setTimeout(function () {
                    //enable magnifying glass for rewards pictures
                    if(!cfg.mobile) $(".js-magnify").magnify({padding: 30});

                    $("img.js-lazyload").each(function (i, e) {
                        var e = $(e);

                        e.attr('src', e.attr('data-original'));
                    });

                    $(".js-lazyload-css").each(function (i, e) {
                        var e = $(e);

                        e.css({background: "url(" + e.attr('data-original') + ") no-repeat"});
                    });

                    var player = $('.video-player');

                    if(player.attr('data-autoplay') == "true") {
                        player.trigger('click');
                    }
                }, 100);

            })
            .trigger('contentload')
            .on('mouseenter mouseleave', '#btn404', function (e) {
                // Funny 404 efekt
                $('body').toggleClass('btn-404-over');

                e.preventDefault();
            });
    });

    $(function() {

        var updateClickable = function(mql) {
            if(typeof mql !== "undefined" && mql.matches) {
                //mobile
                $(".js-clickable-mobile").click(function() {
                    window.location = $(this).attr('data-href');

                    return true;
                });
            } else {
                //not mobile - unregister
                $(".js-clickable-mobile").unbind("click");
            }
        };

        updateClickable(mobileMql);

        typeof mobileMql !== "undefined" && mobileMql.addListener(updateClickable);
    });

    /**
     * Binding for 'countdown' - on campaigns page
     */
    $(function () {
        $('.countdown').each(function () {
            var date = new Date(parseInt($(this).attr("data-date")));
            $(this).countdown({until: date,
                layout: '<table><tr>{d<}<td class="days"><b>{dn}</b> {dl} </td><td class="sep">{sep}</td>{d>}<td class="hours"><b>{hn}</b> {hl}</td><td class="sep">{sep}</td><td><b>{mnn}</b> {ml}</td><td class="sep">{sep}</td><td class="seconds"><b>{snn}</b> {sl}</td></tr></table>',
                format: 'dHMS',
                onExpiry: reloadPage
            });
        });

        /**
         * Refresh page after countdown expires
         */
        function reloadPage() {
            document.location.reload(true);
        }
    });

    /**
     * Campaings page - handling for long text captions
     *
     */
    $(function () {
        if (cfg.page == "timeline") {
            // TODO: this is a quickfix and should be handled thru CSS
            if (window.matchMedia('(min-width: 960px)').matches) {
                $('.raffles-active div.text').each(function () {

                    var height = Math.max(parseInt($(this).height(), 10) - 50, 510);
                    var item = $(this).parents('.raffle');

                    item.css('min-height', height);
                    item.find('.pattern').css('min-height', height);
                    item.find('.gfx').css('min-height', height);
                    item.find('.timeline').css('min-height', height);

                });
            }
        }
    });

    /*
     **************************************************************
     * Video modals
     **************************************************************
     */

    $(function () {
        $('.modal-video').on('show', function () {
            $(this).find('iframe').attr('src', $(this).find('iframe').data('src'));
        });
        $('.modal-video').on('hide', function () {
            $(this).find('iframe').attr('src', '');
        })
    });

    $(function () {
        /*
         **************************************************************
         * Detect touch device
         **************************************************************
         */
        var is_touch_device = 'ontouchstart' in document.documentElement;

        if (is_touch_device) {
            $('html').addClass('touch-device');
        }

        /*
         * Reformat <time> tags with .timeago
         */
        $.timeago.settings.allowFuture = true;
        $('time.timeago').timeago();

        /*
         **************************************************************
         * Tooltips
         **************************************************************
         */

        $('[data-toggle=tooltip]').tooltip();

        /*
         * Handle bootstrap popovers
         */
        $('[data-toggle="popover"]').popover({
            html: true,
            animation: true
        });

        /*
         * Handle external links
         */
        $('a[rel="external"]').attr('target', '_blank');


        $("#subscription-form :input[name='email']").on("keydown", function(e) {
            if (!e) { var e = window.event; }

            // Submit on Enter
            if (e.keyCode == 13) submitSubscription();
        });
    });

    window.submitSubscription = function() {
        //validation
        var emailInput = $("#subscription-form :input[name='email']");
        if(emailInput.val() == "") {
            emailInput.addClass("error");
        } else {
            $('#subscription-form-location').val(document.location.href);
            $('#subscription-form').submit();
        }
    };

    $(function () {
        $('.js-enable-onload').prop("disabled", false);
    });
});

