define('prizeomagnify',["jquery"], function () {
    "use strict";

    (function ( $ ) {

        $.fn.magnify = function (options) {

            var settings = $.extend({
                padding: 0,
                min_ratio: 1.3
            }, options );

            //get image's native size - store it for each element
            this.each(function(i, e) {
                var divElem = $(e);

                //set it up after image has loaded
                divElem.find(".js-magnify-small").load(function(e) {
                    var imgElem = $(this);

                    var image_object = new Image();
                    var image_url = imgElem.attr("src");

                    if (image_url === undefined) return;

                    image_object.src = image_url;

                    divElem.attr("data-width", image_object.width);
                    divElem.attr("data-height", image_object.height);

                    //setup mouse move for each element
                    divElem.mousemove(function (e) {
                        var elem = $(this);

                        var magnify_offset = elem.offset();

                        var mx = e.pageX - magnify_offset.left;
                        var my = e.pageY - magnify_offset.top;


                        var large_wrap = elem.find(".js-magnify-large-wrap");
                        var large = elem.find(".js-magnify-large");
                        var small = elem.find(".js-magnify-small");

                        var native_width = parseInt(elem.attr("data-width"));
                        var native_height = parseInt(elem.attr("data-height"));

                        //disable functionality if original image is too small
                        if (small.width() * settings.min_ratio > native_width || small.height() * settings.min_ratio > native_height) return;

                        if (mx < $(this).width() - settings.padding && my < $(this).height() - settings.padding && mx > settings.padding && my > settings.padding) {
                            large_wrap.fadeIn(100);
                        }
                        else {
                            large_wrap.fadeOut(100);
                        }

                        if (large_wrap.is(":visible")) {
                            //The background position of .large will be changed according to the position
                            //of the mouse over the .small image. So we will get the ratio of the pixel
                            //under the mouse pointer with respect to the image and use that to position the
                            //large image inside the magnifying glass
                            var rx = Math.round(mx / small.width() * native_width - large_wrap.width() / 2) * -1;
                            var ry = Math.round(my / small.height() * native_height - large_wrap.height() / 2) * -1;
                            var bgp = rx + "px " + ry + "px";

                            //Time to move the magnifying glass with the mouse
                            var px = mx - large_wrap.width() / 2;
                            var py = my - large_wrap.height() / 2;
                            //Now the glass moves with the mouse
                            //The logic is to deduct half of the glass's width and height from the
                            //mouse coordinates to place it with its center at the mouse coordinates

                            //If you hover on the image now, you should see the magnifying glass in action
                            large_wrap.css({
                                left: px,
                                top: py
                            });

                            large.css({
                                backgroundPosition: bgp
                            });
                        }
                    });

                });

            });


            return this;
        }
    }(jQuery));

});

