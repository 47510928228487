define('prizeosignin',["jquery", "prizeoutils", "hideshowpassword", "jqcomplexify", "bootstrapmodal"], function ($) {
    "use strict";

    $(function () {
        function isValidEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }

        // Collapsible elements
        function collapsible(id) {
            var speed = 80;
            $('section.collapsible').removeClass('active').slideUp(speed);
            $(id).stop().addClass('active').slideDown(speed);
        }

        window.adjustModalForSignIn = function() {
            $('.form-login').find(".js-signin-only").removeClass('hidden');
            $('.form-login').find(".js-signup-only").addClass('hidden');
        };

        window.adjustModalForSignUp = function() {
            $('.form-login').find(".js-signup-only").removeClass('hidden');
            $('.form-login').find(".js-signin-only").addClass('hidden');
        };

        // hideShowPassword plugin
        $('#sign-pass').hideShowPassword({
            // Creates a wrapper and toggle element with minimal styles.
            innerToggle: true,
            toggleClass: 'my-toggle-class'
        });

        $('*[data-toggle="collapsible"]').click(function (e) {
            messages.hide();

            collapsible($(this).data('target'));
            e.preventDefault();
        });

        $("input#sign-password").complexify({minimumChars: 7, strengthScaleFactor: 0.6}, function (valid, complexity) {
            $('#progress').css('width', complexity + '%');
            if (!valid) {
                $('#passwd-strength').html('Weak');
                $('#progress').removeClass('progressbarValid').addClass('progressbarInvalid');
            } else {
                $('#passwd-strength').html('Good');
                $('#progress').removeClass('progressbarInvalid').addClass('progressbarValid');
            }
        });

        /* Ability to switch tabs on email verification screen */
        $('.signup-tab-container a[data-toggle=tab]').click(function () {
            $('.signup-tab-container .tab').removeClass('active');
            $('.signup-tab-container').find($(this).data('target')).addClass('active');

            messages.hide();
        });


        $('.js-email-sign-in').click(function (e) {
            messages.hide();

            if(! cfg.mobile) {
                e.preventDefault();

                //set it back to first screen
                $('section.collapsible').removeClass('active').slideUp(0);
                $(".js-email-section").stop().addClass('active').slideDown(0);

                //adjust for sign-in
                adjustModalForSignIn();

                $('.js-sign-in-form').modal("show");
            }
        });

        // This prevents scrolling when bootstrap modal is open
        $(".js-sign-in-form").on("show.bs.modal", function () {
            $("html").addClass("modal-open");
        }).on("hide.bs.modal", function () {
            $("html").removeClass("modal-open")
        });

        function validateSignUpForm(form) {
            var errors = false, errorLabel;
            var emailFromForm;

            // reset the form
            form.find('.inp-error').addClass("hidden");
            form.find('.error').removeClass('error');

            // validate email
            emailFromForm = form.find('#sign-email').val();
            if (form.find('#sign-email').size() && (!emailFromForm || !isValidEmail(emailFromForm))) {

                errors = true;

                errorLabel = $('<span></span>');
                errorLabel.text('E-mail is required');
                errorLabel.addClass('inp-error small');

                form.find('#sign-email').addClass('error');
                form.find('#sign-email').parent().after(errorLabel);
            }

            if (errors) {
                $('html,body').animate({scrollTop: 0});
            }

            return !errors;
        }

        if ($('#twitter-signup-submit').length){
            var twitterSignUpSubmitButtonLadda = Ladda.create(document.querySelector('#twitter-signup-submit'));
        }

        $("#twitter-form-signup").submit(function(event){
            event.preventDefault();
            var form = $(this);

            if(! validateSignUpForm(form)) return;

            form.find("input[name=authenticityToken]").val($.cookie("csrftkn"));

            twitterSignUpSubmitButtonLadda.start();

            messages.hide();

            ajaxToUrl(cfg.twitter.submit, form, "#"+form.attr('id'), "post", function() {}, function() {
                twitterSignUpSubmitButtonLadda.stop();

                if (result.status === "error") {
                    var message = "";
                    if (typeof result.error !== "undefined") message = result.error.message;
                    if (typeof result.errors !== "undefined") message = result.errors;

                }
            });
        });

        $("#email-form-signup").submit(function(event) {

            event.preventDefault();
            var form = $(this);

            if(!validateSignUpForm(form)) return;

            if($("#sign-password").val().length < 8){
                var input = $("#sign-password");
                input.addClass("error");
                var paparent = input.parent().parent();
                paparent.addClass("error");
                paparent.children().last().text(polyglot.t("validation.passwordLength.js"));
                paparent.children().last().show();
                return;
            }

            form.find("input[name=authenticityToken]").val($.cookie("csrftkn"));

            emailSignUpSubmitButtonLadda.start();

            messages.hide();

            ajaxToUrl(cfg.authentication.signUpSubmit, form, "#"+form.attr('id'), 'post', function () {},
                function (result) {
                    emailSignUpSubmitButtonLadda.stop();

                    if (result.status === "error") {
                        var message = "";
                        if (typeof result.error !== "undefined") message = result.error.message;
                        if (typeof result.errors !== "undefined") message = result.errors;

                    }
                }
            );
        });

        if($('.js-email-signin-button').length) {
            var emailSignInSubmitButtonLadda = Ladda.create(document.querySelector('.js-email-signin-button'));
        }

        $("#email-form-signin").submit(function(event){
            event.preventDefault();
            var form = $(this);

            //if(! validateSignUpForm(form)) return;

            emailSignInSubmitButtonLadda.start();

            form.find("input[name=authenticityToken]").val($.cookie("csrftkn"));

            messages.hide();

            ajaxToUrl(cfg.authentication.signInSubmit, form, '#email-form-signin', 'post', function (result) {}, function (result) {
                emailSignInSubmitButtonLadda.stop();

                if (result.status === "error") {
                    var message = "";
                    if (typeof result.error !== "undefined") message = result.error.message;
                    if (typeof result.errors !== "undefined") message = result.errors;

                }
            });
        });
    });
});

