define('prizeotwitter',["jquery", "prizeoutils"], function ($) {
    "use strict";

    $(function () {
        $(document).on("click", ".js-btn-twitter", function (e) {
            e.preventDefault();
            $(".js-btn-twitter").addClass('disabled-link'); //prevent multiple clicks

            var handleNewsletterSubscription = $("#js-receive-newsletter-login").length > 0
            var receiveNewsletter = $("#js-receive-newsletter-login").is(":checked");

            var signUp = $(".js-signup-only.hidden").size() != 0 && $(".js-signin-only.hidden").size() == 0;
            $.cookie("signInUp", signUp ? "signUp" : "signIn");

            var origin = cfg.origin;

            if(cfg.checkoutReward) {
                origin = cfg.raffle.checkout + "?reward=" + cfg.checkoutReward + "&amountToPay=" + cfg.checkoutAmount;
            }

            var params = {
                "originUrl": origin,
                "handleNewsletterSubscription": handleNewsletterSubscription,
                "receiveNewsletter": receiveNewsletter
            };

            postToURL(
                cfg.twitter.connect,
                params
            );
        });

        /* Twitter API bindings */
        twttr.ready(
            function (twttr) {
                if(cfg.page == "thank-you") {
                    twttr.events.bind(
                        'follow',
                        function (event) {
                            if(cfg && cfg.url && cfg.url.addBonusForFollowing) {
                                $.ajax({type: "POST",
                                    url: cfg.url.addBonusForFollowing,
                                    data: {authenticityToken: $.cookie("csrftkn")}
                                }).success(function (response) {
                                    if(response.status == "ok") {
                                        window.clickedLikeOrFollow = true;

                                    }
                                });
                            }
                        }
                    );
                }

                if(cfg.page == "campaign") {
                    $('twitterwidget').css({'margin-bottom': "0px", 'margin-top': "0px"});
                }
            }
        );

    });
});

