define('prizeofacebook',["jquery", "prizeoutils"], function ($) {
    "use strict";

    // Load the Facebook SDK asynchronously
    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = "//connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    // do post request to server and authenticate the user
    function authenticateFacebook(facebookId, facebookToken, URL, originUrl, handleNewsletterSubscription, receiveNewsletter) {
        // default for receiveNewsletter is false
        receiveNewsletter = (typeof receiveNewsletter === "undefined") ? false : receiveNewsletter;
        handleNewsletterSubscription = (typeof handleNewsletterSubscription === "undefined") ? false : handleNewsletterSubscription;

        $.cookie("signInUp", cfg && cfg.signUp ? "signUp" : "signIn");

        var params = {
            "facebookId": facebookId,
            "facebookToken": facebookToken,
            "originUrl": originUrl,
            "receiveNewsletter": receiveNewsletter,
            "handleNewsletterSubscription": handleNewsletterSubscription
        };

        postToURL(URL, params);
    }

    function facebookGetMissingPermissions(permissions, requiredPermissions) {
        var missing = [];

        for (var i = 0; i < requiredPermissions.length; i++) {
            //note: JS standard doesn't have indexOf, or contains ..

            var j;
            for (j = 0; j < permissions.length; j++) {
                if (permissions[j] == requiredPermissions[i]) break;
            }

            if (j == permissions.length) missing.push(requiredPermissions[i]);
        }

        return missing;
    }

    function facebookAskForPermissions(missingPermissions, target_url) {
        //compile message for user
        var explanation = polyglot.t('fcbPermExplanation.js');
        for (var i = 0; i < missingPermissions.length; i++) explanation += " " + polyglot.t('fcbPerm_' + missingPermissions[i] + '.js');

        messages.info(explanation);

        //give user some time to process the flash message
        setTimeout(function () {
            //try to get them again

            top.location = 'https://www.facebook.com/dialog/oauth?client_id=' + cfg.facebook.appId + '&auth_type=rerequest&scope=' + missingPermissions.join(",") + '&redirect_uri=' + encodeURI(target_url) + '&state=csrftkn' + $.cookie("csrftkn");
        }, 3000);
    }

    /**
     * Should be called before any API function that requires permissions is executed and also after logging in
     *
     * @param requiredPermissions
     * @param fnc_ok
     * @param fnc_error
     */
    window.facebookExecuteWithPermissionCheck = function (requiredPermissions, fnc_ok, target_url) {
        //This assumes the user is already logged in, we are just checking if we have the right perms

        if (typeof requiredPermissions === 'string') requiredPermissions = requiredPermissions.split(",");

        FB.api('/me/permissions', function (response) {
            var permissionsRaw = response.data;
            var permissions = [];

            for (var i = 0; i < permissionsRaw.length; i++) {
                var p = permissionsRaw[i];
                if (p.status === "granted") permissions.push(p.permission);
            }

            var missing = facebookGetMissingPermissions(permissions, requiredPermissions);

            if (missing.length === 0) fnc_ok();
            else {
                facebookAskForPermissions(missing, target_url);
            }
        });
    };

    /** Function logs the user in and returns to the current URL
     *
     * @param status string status that is passed back on return
     */
    window.facebookLogin = function (fnc_ok, redirect_url, fnc_error, desiredPermissions) {
        if(typeof desiredPermissions === 'undefined') {
          desiredPermissions = cfg.facebook.perm_basic;
        }

        try {
            FB.getLoginStatus(function (response) {
                if (response.status === 'connected') {
                    // the user is logged in to facebook and has authenticated the application
                    cfg.facebookId = response.authResponse.userID;
                    cfg.facebookToken = response.authResponse.accessToken;

                    facebookExecuteWithPermissionCheck(desiredPermissions, fnc_ok, redirect_url);
                } else {
                    var newsletter = $("#js-receive-newsletter-login");
                    var handleNewsletterSubscription = newsletter.length > 0 ? '1':'0';
                    var receiveNewsletter = newsletter.is(":checked") ? '1':'0';
                    $.cookie('newsletter', handleNewsletterSubscription + receiveNewsletter);

                    top.location = cfg.facebook.facebookGraph + '/oauth/authorize?client_id=' + cfg.facebook.appId + '&response_type=code&scope=' + desiredPermissions + '&redirect_uri=' + encodeURI(redirect_url);
                }
            });
        } catch (err) {
            // something went wrong with the facebook authentication
            messages.error(polyglot.t('fcbUnreachable.js'));

            fnc_error();
        }
    };

    function bindFacebookHandler(selector, URL, fnc_ok, fnc_error) {
        // Bind the link with facebook button gets the user facebook credentials and authenticates him

        $.cookie("signInUp", cfg && cfg.signUp ? "signUp" : "signIn");

        $(document).on("click", selector, function (e) {
            e.preventDefault();

            var elements = $(selector);

            elements.addClass('disabled-link'); //prevent multiple clicks

            // show loading message if no message is shown
            if (!messages.isVisible()) {
                // message wants to be executed in a separated thread
                setTimeout(function () {
                    messages.ok(polyglot.t('waitingForFacebook.js'));
                });
            }

            facebookLogin(fnc_ok, URL(), fnc_error);
        });
    }

    function getCurrentFacebookUser(callback) {
        try {
            FB.getLoginStatus(function (response) {
                if (response.status === 'connected') {
                    var facebookId = response.authResponse.userID;
                    var facebookToken = response.authResponse.accessToken;

                    return callback(facebookId, facebookToken);
                }
            });
        } catch (err) {
            return false;
        }
    }

    window.fbAsyncInit = function () {
        FB.init({
            appId: cfg.facebook.appId,
            cookie: true,  // enable cookies to allow the server to access the session
            xfbml: true,  // parse social plugins on this page
            version: 'v6.0' // use version 6.0
        });

        window.react.asyncCallbacks.onFacebookSdkLoaded.forEach(
          function(cb){
            cb();
          }
        );

        FB.Event.subscribe('edge.create', function (href, widget) {
            if(cfg && cfg.url && cfg.url.addBonusForFollowing) {
                $.ajax({type: "POST",
                    url: cfg.url.addBonusForFollowing,
                    data: {authenticityToken: $.cookie("csrftkn")}
                }).success(function (response) {
                    if(response.status = "ok") {
                        window.clickedLikeOrFollow = true;

                    }
                });

            }
        })
    };

    $(function () {

        function getAuthCallback() {
            var currentUrl = window.location.href;

            if(cfg.checkoutReward) {
                currentUrl = cfg.raffle.checkout + "?reward=" + cfg.checkoutReward + "&amountToPay=" + cfg.checkoutAmount;
            }

            return cfg.facebook.callbackAuthenticateUrl +
                (cfg.facebook.callbackAuthenticateUrl.indexOf("?") == -1 ? "?" : "&") +
                "originUrl=" + encodeURI(currentUrl);
        }

        //main sing up / sign in
        bindFacebookHandler(
            ".js-btn-facebook",
            getAuthCallback,
            function() {
                var handleNewsletterSubscription = $("#js-receive-newsletter-login").length > 0;
                var receiveNewsletter = $("#js-receive-newsletter-login").is(":checked");

                var origin = cfg.origin;

                if(cfg.checkoutReward) {
                    origin = cfg.raffle.checkout + "?reward=" + cfg.checkoutReward + "&amountToPay=" + cfg.checkoutAmount;
                }

                authenticateFacebook(cfg.facebookId, cfg.facebookToken, cfg.facebook.authenticateUrl, origin, handleNewsletterSubscription, receiveNewsletter);
            },
            function() {}
        );
    });

});

