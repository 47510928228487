//
// Mail Address Validation Plugin
//
// Attaching to a form:
//
//    $('jquery_selector').mail_validator({
//        in_progress: in_progress_callback, // called when request is made to validator
//        success: success_callback,         // called when validator has returned
//        error: validation_error,           // called when an error reaching the validator has occurred
//    });
//
//
// Sample JSON in success callback:
//
//  {
//      "valid": true,
//      "suggestion": null
//  }
//


$.fn.mail_validator = function(options) {
    return this.each(function() {
        $(this).focusout(function() {
            run_validator($(this).val(), options, $(this));
        });
    });
};


// Export the run_validator function
define('mail_validator',[],function() { return {ajax: run_validator, immediate: with_regex} });

// Checking email format with a regex will catch 99% of people's typos.
function with_regex(str) {
  // Simple validation of an email address using a regex
  var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return str && regex.test(str);
}

function run_validator(address_text, options) {
    var error_message;

    if (!address_text) {
        return;
    }

    if (address_text.length > 512) {
        error_message = 'Stream exceeds maximum allowable length of 512.';
        if (options && options.error) {
            options.error(error_message);
        } else {
            console.log(error_message);
        }
        return;
    }

    if (options && options.in_progress) {
        options.in_progress();
    }
    $.ajax({
        type: "POST",
        url: cfg.url.validateEmail + address_text,
        dataType: "json",
        success: function(data) {
            if (data.valid) {
                options.success(data);
            } else {
              if(options && options.invalid) options.invalid();
            }
        },
        error: function() {
            error_message = 'Error occurred, unable to validate address.!!';

            if (options && options.error) {
                options.error(error_message);
            } else {
                console.log(error_message);
            }
        }
    });
}
;
