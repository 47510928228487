define('prizeodatacollection',["jquery", "bootstrapmodal", "jqchosen"], function ($) {
    "use strict";

    $(function () {

        if(cfg.page === "data-collection") {

            $.dataCollection = function (el, options) {

                // To avoid scope issues, we're using 'dataCollection' instead of 'this'
                // to reference this class from internal events and functions.
                var dataCollection = this;

                // Access to jQuery and DOM versions of element
                var $container = $(el);

                dataCollection.defaultOptions = {
                    // defaults
                };

                // Total number of required questions
                dataCollection.requiredQuestions = 0;

                // Prev/Next bind events
                $container.find('.next').on('click', function () {
                    dataCollection.next();
                });
                $container.find('.prev').on('click', function () {
                    dataCollection.prev();
                });

                dataCollection.checkProgress = function () {

                    var answeredQuestions = 0;

                    // Get number of answered questions
                    $container.find('[data-required]').each(function () {

                        if ($(this).val() != '') {
                            answeredQuestions++;
                        }

                    });

                    if (dataCollection.requiredQuestions > 0) {

                        $(".chosen-select").trigger("chosen:updated");

                        var percentCompleted = Math.round(100 * answeredQuestions / dataCollection.requiredQuestions, 0);

                        if (percentCompleted == 0) {

                            $('.progress').fadeOut();

                        } else if (percentCompleted == 100) {

                            $('.progress').fadeIn().addClass('completed');
                            $('.progress p').css('width', '100%');
                            $('#datacollection-submit').addClass("hidden");

                        } else {

                            $('.progress').fadeIn().removeClass('completed');
                            $('.progress p').stop().animate({width: percentCompleted + '%'}, 300);
                            $('.progress label').text(percentCompleted + '% completed');
                            $('#datacollection-submit').removeClass("hidden");
                        }
                    }
                };

                // Initialize data-collection
                dataCollection.init = function () {

                    // Extend options
                    dataCollection.options = $.extend({}, dataCollection.defaultOptions, options);

                    // Bind form validation
                    $container.find('form').on('submit', function (e) {
                        if (!dataCollection.validate()) {
                            //do not submit
                            e.preventDefault();
                            return false;
                        } else {
                        }

                        return true;
                    });

                    // Get total number of required questions
                    dataCollection.requiredQuestions = $container.find('[data-required]').size();

                    // Show real-time progress
                    $container.find('input, textarea, select').on('focus change keyup', function () {
                        dataCollection.checkProgress();
                    });

                };

                // Validate dataCollection form
                dataCollection.validate = function (e) {
                    // Where to scroll
                    var scroll = 9999;
                    var hasErrors = false;

                    //possibly not needed - FireFox is playing tricks by disabling it automatically in some cases
                    $('#datacollection-submit').prop('disabled', false);
                    $('#datacollection-footer-submit').prop('disabled', false);

                    // Remove previous errors
                    $container.find('p.error').addClass("hidden");

                    $container.find('[data-required]').each(function () {
                        if ($(this).val() == "") {
                            var parent = $(this).parents('.form-part');
                            hasErrors = true;
                            scroll = Math.min(scroll, parent.offset().top - 100);
                            parent.find('.error').removeClass("hidden");
                        }
                    });

                    // tell Pepo why we're checking for scroll != 9999 here
                    if (hasErrors && scroll != 9999) {
                        $('html,body').animate({ scrollTop: scroll }, 300);
                    }

                    return !hasErrors;
                };

                // Run initializer
                dataCollection.init();
            };

            $.fn.dataCollection = function (options) {
                return this.each(function () {
                    (new $.dataCollection(this, options));
                });
            };

            $('#data-collection').dataCollection();

            /*
             * jQuery chosen select (makes select boxes searchable)
             */
            var isSafari = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1 && navigator.userAgent.indexOf('PhantomJS') == -1;
            var isTest = navigator.userAgent.indexOf('PhantomJS') != -1;

            if (!isSafari && !isTest) {
                $(".chosen-select").chosen({disable_search_threshold: 10});
            }

            $("[data-required]").trigger("change");
        }
    });
});

